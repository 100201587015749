import React from 'react';
import ReactGA from 'react-ga';
import HomepageCollar from '../../../assets/images/homepage_collar.png';
import SecondaryButton from '../../../components/secondary-button';

const SwitchFooter = () => {
  return (
    <div className="relative bg-white bg-left bg-no-repeat bg-cover xl:bg-contain" style={{ backgroundColor: '#D6B7F2', backgroundImage: `url(${HomepageCollar})` }}>
      <div className="mx-auto max-w-md py-16 px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-5xl">
        <p className="mt-2 text-5xl lg:text-6xl font-extrabold text-white tracking-tight uppercase font-condensed">
          Ready to make the switch?
        </p>
        <div className="mt-10 sm:flex sm:justify-center lg:justify-center">
          <div className="rounded-3xl shadow-xl pb-2 sm:pb-0 sm:pr-2">
            <SecondaryButton copy="Get a Demo" href="https://calendly.com/milo-demo/30min" 
onClick={() => ReactGA.event({ category: 'DEMO', action: 'Schedule Demo', label: 'Home Page Footer' })}/>
          </div>
          <div className="rounded-3xl shadow-xl">
            <SecondaryButton copy="Contact us" href="/contact" />
          </div>
          {/* <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <SecondaryButton copy="Feature tour" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SwitchFooter;
