import React from 'react';
import Stack from '../../assets/images/careers/stack.png';
import Logo from '../../assets/images/careers/logo.png';
import Logo2 from '../../assets/images/careers/logo2.png';
import Logo3 from '../../assets/images/careers/logo_s.png';
import Logo4 from '../../assets/images/careers/logo_xs.png';
import Logo5 from '../../assets/images/careers/screens.png';
import Logo6 from '../../assets/images/careers/screens2.png';
import Logo7 from '../../assets/images/careers/screens_s.png';
import Logo8 from '../../assets/images/careers/screens_xs.png';
import Logo9 from '../../assets/images/careers/logo2x.png';
import Video from '../../assets/images/careers/demo.mp4';


const Features = () => {
  return (
    <div className="bg-white overflow-hidden">
        <div className="h-[10000px]">
          <video src={Video} controls>Your browser does not support video</video>
            <img
                className=" "
                src={Stack}
                alt="A screenshot showing billing items for a current patient visit"
              />
  <img
                className=" "
                src={Logo}
                alt="A screenshot showing billing items for a current patient visit"
              />
                <img
                className=" "
                src={Logo2}
                alt="A screenshot showing billing items for a current patient visit"
              />

<img
                className=" "
                src={Logo3}
                alt="A screenshot showing billing items for a current patient visit"
              />


<img
                className=" "
                src={Logo4}
                alt="A screenshot showing billing items for a current patient visit"
              />


<img
                className=" "
                src={Logo5}
                alt="A screenshot showing billing items for a current patient visit"
              />


<img
                className=" "
                src={Logo6}
                alt="A screenshot showing billing items for a current patient visit"
              />


<img
                className=" "
                src={Logo7}
                alt="A screenshot showing billing items for a current patient visit"
              />


<img
                className=" "
                src={Logo8}
                alt="A screenshot showing billing items for a current patient visit"
              />


<img
                className=" "
                src={Logo9}
                alt="A screenshot showing billing items for a current patient visit"
              />


</div>


      
  
    </div>
  )
}

export default Features;
