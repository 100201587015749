import React from 'react';

import CtaLink from '../../../components/cta-link';

import HomepageOtherLogos from '../../../assets/images/homepage_other_logos.png';

const MiddleLogos = () => {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-16">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-6xl">
        <p className="text-left lg:text-center mt-2 text-5xl lg:text-6xl font-extrabold text-gray-900 tracking-tight uppercase font-condensed ">
          Just because our software does everything, doesn’t mean we do it all ourselves.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          To make an all-in-one solution, we rely on partners and solutions to power everything from making a phone call to printing a prescription shipping label.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          This means you don’t just have the Milo team bringing you the latest technology, but hundreds of thousands of the brightest minds from across the world.
        </p>
        <div className="mt-12 max-w-4xl m-auto">
          <img src={HomepageOtherLogos} alt="A wall of logos showing all the companies that work with Milo" />
        </div>
        <CtaLink copy="See how we make integrating software easy" href="/contact" mttop="mt-2 text-xl" />
      </div>
    </div>
  );
};

export default MiddleLogos;
