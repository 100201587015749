import React from 'react';

import HeroSection from './sections/hero';
import MiddleCopy from './sections/middle-copy';
import FooterMain from '../../components/footer';
import Footer from '../../components/stickyfooter';
import Capture from '../../components/capture';
import ScreenshotGallery from './sections/screenshot-gallery';
import MiddleFacts from './sections/middle-facts';
import MiddleLogos from './sections/middle-logos';
import LeftRightScreenshotVariation from './sections/left-right-screenshot-variation';
import MissionStatement from './sections/mission';
import TheTeam from './sections/the-team';
import SwitchFooter from './sections/switch-footer';
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';

import ReactGA from 'react-ga';
import Replaces from '../../components/sections/replaces';
import LeftRightScreenshot from '../../components/sections/left-right-screenshot';
// import Phone from '../../components/phone';


import HomepageVoipSidebarScreenshot from '../../assets/images/homepage_voip_sidebar_screenshot2.png';

const HomePage = () => {
  return (
    <div className="bg-white overflow-hidden">

      <HeroSection />
      <MiddleCopy />
      <ScreenshotGallery />
      {/* <Replaces /> */}

      <MiddleFacts />
      <LeftRightScreenshot
        title="Built inside a hospital, by people who know their way around."
        copy={
          [
            "A lot of veterinary software companies are founded by former doctors and employees of the industry.",
            "Milo was built much differently: for one, many of us had never worked inside an animal hospital before. As we learned how veterinary practices were run, we kept asking why.",
            "Fast forward three years later and you’ll find that our software is incredibly practical. Every feature was born out of need or solving a problem that we experienced."
          ]
        }
        quote="Taking phone calls from clients is much more enjoyable with Milo. Every inbound phone call results in a sidebar appearing with quick one-click access to the client & patient chart, and log notes about the call."
        quoteAuthor="Paloma Grate, Hospital Administrator"
        ctaCopy="Learn about what makes Milo different"
        ctaHref="./../about-us"
        screenshot={HomepageVoipSidebarScreenshot}
        screenshotAlt="A screenshot showing an inbound phone call notification"
      />
      <MiddleLogos />
      <LeftRightScreenshotVariation />
      <TheTeam />
      <SwitchFooter />
      <FooterMain />
      {/* <Footer /> */}
      
 

  


      {/* <Phone /> */}

      {/*
      <Capture /> */}




    </div>
    
    
  )
}

export default HomePage;
