import aaron from "../../../assets/images/headshots/aaron.jpeg";
import allison from "../../../assets/images/headshots/allison.jpeg";
import andrew from "../../../assets/images/headshots/andrew.jpeg";
import anton from "../../../assets/images/headshots/anton.jpeg";
import casey from "../../../assets/images/headshots/casey.jpeg";
import danielle from "../../../assets/images/headshots/danielle.jpeg";
import dena from "../../../assets/images/headshots/dena.jpeg";
import diogo from "../../../assets/images/headshots/diogo.jpeg";
import erik from "../../../assets/images/headshots/erik.jpeg";
import evan from "../../../assets/images/headshots/evan.jpeg";
import ian from "../../../assets/images/headshots/ian.jpeg";
import mary from "../../../assets/images/headshots/mary.jpeg";
import matt from "../../../assets/images/headshots/matt.jpeg";
import mel from "../../../assets/images/headshots/mel.jpeg";
import raf from "../../../assets/images/headshots/raf.jpeg";
import rony from "../../../assets/images/headshots/rony.jpeg";
import sarah from "../../../assets/images/headshots/sarah.jpeg";
import sass from "../../../assets/images/headshots/sass.jpg";
import sedat from "../../../assets/images/headshots/sedat.jpeg";

export const people = [
  {
    name: "Aaron",
    role: "Chief Customer Officer",
    imageUrl: aaron,
    bio: "🇺🇸 California: As Milo's Head of Customer Success, Aaron is responsible for onboarding new users of Milo and more importantly, ensuring their ongoing happiness for years to come.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/abata/",
  },
  {
    name: "Allison",
    role: "Software Engineer",
    imageUrl: allison,
    bio: "🇺🇸 Washington: Allison's skills are in back-end development where she supports the technology behind the scenes of Milo.  She works closely with the broader teams to constantly improve our existing features as well as contribute to feature development.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/allisonmariemackey/",
  },
  {
    name: "Andrew",
    role: "Senior Software Engineer",
    imageUrl: andrew,
    bio: "🇺🇸 Maine: Andrew is an experienced front-end software developer, and a senior member of our engineering team. He is the person behind the curtain building the interactive experiences for Milo users.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/andrewrmueller/",
  },
  {
    name: "Anton",
    role: "Senior Software Engineer",
    imageUrl: anton,
    bio: "🇷🇺 Russia: Anton is a senior member of our engineering team that builds the technology you see inside our software every day. He specializes in seamlessly transferring your data into Milo.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/antongudkov/",
  },

  {
    name: "Casey",
    role: "Customer Success Manager",
    imageUrl: casey,
    bio: "🇺🇸 Colorado: We met Casey over two years before she joined Milo. She was our very first customer of Milo software and has taught us so much. Much of what you see in our software today was a direct result of her expertise.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/company/milopets",
  },
  {
    name: "Danielle",
    role: "Product Manager",
    imageUrl: danielle,
    bio: "🇺🇸 New York: Danielle serves a few roles at Milo, first as a member of our executive team handling everything from finance to legal and everything in between. She also manages our customer support and is one of our product managers, working with Milo customers to design software that meets their needs.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/danielle-hennessy-51255544/",
  },
  {
    name: "Dena",
    role: "UX Designer",
    imageUrl: dena,
    bio: "🇺🇸 New York: Dena is our Head of Design and UX. She spends hours in the details every day to ensure that every color, alignment, font size and pixel are perfectly placed to give you the best experience possible.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/dena-handler-33831615b/",
  },
  {
    name: "Diogo",
    role: "Head of Engineering",
    imageUrl: diogo,
    bio: "🇵🇹 Portugal: Diogo joined our team in September of 2021. Although he's relatively new at Milo, he comes to us with experience building human healthcare solutions.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/rosadiogo/",
  },
  {
    name: "Erik",
    role: "Tech Lead",
    imageUrl: erik,
    bio: "🇺🇸 Arizona: Erik is our front-end tech lead. When he's not breathing life into Milo's beautiful, customer-centric UX, he can be found collaborating with Ian (who leads the back-end development) as well as our product team to ensure Milo's engineering team is operating at full efficiency.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/erikclopez/",
  },
  {
    name: "Evan",
    role: "Co-founder & CEO",
    imageUrl: evan,
    bio: "🇺🇸 New York: Evan's focus at Milo is ensuring that Milo remains accountable to its mission of making teams that work in animal healthcare happy.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/evanamaridou/",
  },
  {
    name: "Ian",
    role: "Tech Lead",
    imageUrl: ian,
    bio: "🇺🇸 Maryland: Ian is our back-end tech lead, tasked with ensuring everything behind the scenes in the Milo software is working seamlessly.  He partners closely with the product and front-end teams to ensure their vision is brought to life. ",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/ian-moss-95531122a/",
  },
  {
    name: "Mary",
    role: "Software Engineer",
    imageUrl: mary,
    bio: "🇺🇸 New York: Mary is a full-stack software engineer meaning she can do just about anything. The reason you'll remember Mary's name is that she is an expert in integrating with third parties. Whether that is Twilio's texting API, Antech or IDEXX Labs or integrated X-rays, she can do it all.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/mary-farner/",
  },
  {
    name: "Matt J.",
    role: "Co-founder & CTO",
    imageUrl: matt,
    bio: "🇺🇸 Colorado: Matt is Milo's Chief Technology Officer. Beyond leading our team of software engineers to build and maintain Milo's platform, he's one of the first faces you are likely to meet as he handles most initial conversations and demos. Internally, Matt is known for solving just about any challenge and innovating on new R&D to improve the in-hospital experience.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/matthew-jones-32604a174/",
  },
  {
    name: "Matt S.",
    role: "Chief of Staff",
    imageUrl: sass,
    bio: "🇺🇸 New York: Matt (more affectionately referred to as Sass) brings an abundance of startup experience to the Milo team. His primary focus is conducting the flow of business and making sure we stay inspired and empowered to efficiently create our best work.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/mattsass/",
  },
  {
    name: "Mel",
    role: "Product Marketing Manager",
    imageUrl: mel,
    bio: "🇺🇸 Colorado: Mel owns how we present and communicate Milo to the outside world. Experienced in product development and customer experience, Mel speaks the language of every user. She collaborates with every team to ensure that our features and brand represent our mission.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/melanieesterman/",
  },
  {
    name: "Raf",
    role: "Software Engineer",
    imageUrl: raf,
    bio: "🇺🇸 Tennessee: Raf's speciality lies in front-end software development which means he spends his time building the beautiful UX that Milo customers interact with every day.  He works closely with Dena and the product managers to bring our products to life.",
    twitterUrl: "#",
    linkedinURL: " https://www.linkedin.com/in/rafcevallos/",
  },
  {
    name: "Rony",
    role: "Software Engineer",
    imageUrl: rony,
    bio: '🇵🇰 Bangladesh: Rony\'s specialty lies in "back-end" software development, meaning the technology that drives how our software works behind the scenes.',
    twitterUrl: "#",
    linkedinURL: " https://www.linkedin.com/in/rony36/",
  },
  {
    name: "Sarah",
    role: "Chief Operating Officer",
    imageUrl: sarah,
    bio: "🇺🇸 Vermont: Sarah is Milo's Chief Operating Officer. Her mandate includes running Milo's day to day operations, ensuring we ourselves have a team of happy employees and defining Milo's involvement in the broader veterinary community, both commercially and charitably.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/sarah-hasazi-6091b848/",
  },
  {
    name: "Sedat",
    role: "Senior Software Engineer",
    imageUrl: sedat,
    bio: "🇹🇷 Turkey: Sedat's job is to build the beautiful user experiences that you see everyday. He works closely with Dena and our other product managers to bring our designs to life. His role is critical, seeing as he develops the pieces of Milo that you interact with each and every day.",
    twitterUrl: "#",
    linkedinURL: "https://www.linkedin.com/in/sedat-başar-487b6567/",
  },
  // More people...
];

export default people;
