import React from 'react';
import HeroSection from './sections/hero';

// import PrimaryButton from '../../components/primary-button';
import SecondaryButton from '../../components/secondary-button';
import FooterMain from '../../components/footer';
import ReactGA from 'react-ga';

const Terms = () => {
    return (
        <div className="bg-white overflow-hidden">
            <HeroSection />


            <FooterMain />
        </div>
    )
}

export default Terms;
