import React from 'react';

import Nav from '../../../components/nav/black2';
import ReactGA from 'react-ga'
import HeroTeam from '../../../assets/images/careers/hero_team_wide.jpg';
import HeroTeamSk from '../../../assets/images/careers/hero_team_wide_skinny.jpg';
import HeroTeamMobile from '../../../assets/images/careers/hero_team_mobile.jpg';
import PrimaryButton from '../../../components/primary-button';
import ColorBlend from '../../../components/color-blend2';
import SecondaryButton from '../../../components/secondary-button';

const HeroSection = () => {
  return (
    <div className="bg-white">
      <div className="relative">
        <ColorBlend />
        <div className="relative pt-6 pb-6 sm:pb-6 z-1 xl:pb-1">
          <Nav />

          <main className="lg:relative">
            <div className="mx-auto max-w-7xl w-full pt-16 pb-4 text-center lg:pt-18 lg:pb-4 xl:pt-16 xl:pb-8 lg:text-left " >
              <div className="px-4 lg:w-3/5 sm:px-8 xl:pr-16 ">
                <h1 className=" tracking-tight font-semibold text-4xl leading-[3rem] sm:text-5xl sm:leading-[3.5rem] lg:text-6xl lg:leading-[4rem] xl:text-7xl xl:leading-[5rem] bg-blend-overlay">
                  <span className="block bg-blend-overlay bg-clip-text bg-gradient-to-br from-black via-black/90 to-black/80 ">If you like technology, pets and working with great people, you'll like working at Milo</span>
                </h1>
                <p className="mt-3 max-w-md mx-auto text-normal text-gray-800 text-xl md:mt-5 md:max-w-3xl">
                  Our mission is to make teams that work in the pet healthcare industry happy.
                </p>
                <p className="mt-3 max-w-md mx-auto text-normal text-gray-800 text-xl md:mt-5 md:max-w-3xl">
                  Milo was founded on the idea that the easiest way to create a great customer experience is to first create a great employee experience.
                </p>
                <p className="mt-3 max-w-md mx-auto text-normal text-gray-800 text-xl md:mt-5 md:max-w-3xl">
                  We’re building software that makes it easier for teams inside of veterinary hospitals to achieve their full potential.
                </p>
                <p className="mt-3 max-w-md mx-auto text-normal text-gray-800 text-xl md:mt-5 md:max-w-3xl">
                  And we understand that who we are at Milo is just as important as what we do. In fact, when the founders of Milo sat down four years ago to think about what company to start, there was only one requirement: to build a great place to work.
                </p>
                <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-3xl shadow-xl">
                    <PrimaryButton copy="See Open Roles" href="https://jobs.getmilo.com" onClick={() => ReactGA.event({ category: 'JOBS', action: 'See Jobs', label: 'Jobs Hero' })} />
                  </div>
                  <div className="p-2" />
                  <div className="rounded-3xl shadow-xl">
                    <SecondaryButton copy="Meet Our Team" href="/about-us" />
                  </div>
                </div>
              </div>
            </div>
            <div className="relative w-full h-[420px] sm:h-[500px] md:h-[640px] lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/5 xl:w-2/5 lg:h-2/3 xl:h-4/5 2xl:max-w-3xl" >
              <img
                className="absolute  pt-8 pb-12 top-0 lg:top-16 w-full h-full object-cover object-top lg:object-left z-50 lg:hidden"
                src={HeroTeamMobile}
                alt=""
              />
              <img
                className="hidden xl:flex transform xl:mx-8 absolute top-0 xl:top-16 z-1"
                src={HeroTeam}
                alt=""
              />
              <img
                className="hidden lg:flex transform lg:mx-8 absolute top-0 lg:top-16 xl:hidden z-1"
                src={HeroTeamSk}
                alt=""
              />
            </div>
          </main>
        </div>
      </div>
    </div >
  )
}

export default HeroSection;
