export const WHITE = '#FFFFFF';
export const BLACK = '#333333';
export const MIDNIGHT = '#2E3A46';
export const TWILIGHT = '#4D646D';
export const TURQUOISE = '#60B2A3';
export const CYAN = '#6094B2';
export const PURPLE = '#6C63FF';
export const MAROON = '#B26060';

export const GRAY0 = '#4D646D';
export const GRAY1 = '#A3A3A3';
export const GRAY2 = '#C4C4C4';
export const GRAY3 = '#E5E5E5';
export const GRAY4 = '#F9F9F9';

export const GRAY_TEXT = GRAY0;
export const LIGHTGRAY_TEXT = GRAY1;
export const BORDER = GRAY3;
export const BORDER_CARD = GRAY1;
export const BACKGROUND = WHITE;
export const BACKGROUND_ACCENT = MIDNIGHT;
export const TEXT_PRIMARY = MIDNIGHT;
export const TEXT_SECONDARY = GRAY_TEXT;
export const LINK = TURQUOISE;


export const PRIMARY1 = '#6C63FF';
export const PRIMARY2 = '#8982FF';
export const PRIMARY3 = '#A7A1FF';
export const PRIMARY4 = '#C4C1FF';
export const PRIMARY5 = '#E2E0FF';
export const PRIMARY6 = '#F0EFFF';
export const PRIMARY7 = '#F8F7FF';
