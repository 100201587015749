import React from 'react';
import ReactGA from 'react-ga';

import Nav from '../../../components/nav';

import HomepageHeroScreenshots from '../../../assets/images/homepage_hero_screenshots2.png';
import Team from '../../../assets/images/careers/hero_team_mobile.png';
import HomepageHeroScreenshotsxl from '../../../assets/images/homepage_hero_screenshots2.png';
import PrimaryButton from '../../../components/primary-button';
import ColorBlend from '../../../components/color-blend';
import SecondaryButton from '../../../components/secondary-button';





                  
const HeroSection = () => {
  return (
    <div className="bg-white">
      <div className="relative">
        <ColorBlend />
        <div className="relative pt-6 pb-16 sm:pb-12">
          <Nav />


          <main className="lg:relative">
            <img className="lg:hidden width-full  pt-8 px-2 md:px-16 -mb-8" src={Team} />
            <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:pt-18 lg:pb-20 xl:pt-16 xl:pb-32 lg:text-left " >
              <div className="px-4 lg:w-3/5 sm:px-8 xl:pr-16 ">
                <h1 className=" tracking-tight font-semibold text-4xl sm:text-5xl lg:text-6xl xl:text-7xl  bg-blend-overlay">
                  <span className="block bg-blend-overlay bg-clip-text bg-gradient-to-br from-black via-black/90 to-black/80 ">Software</span>{' '}
                  <span className="hidden lg:block text-transparent  bg-blend-overlay bg-clip-text bg-gradient-to-r from-white/90 via-white/80 to-white/60  ">for animal hospitals</span>{' '}
                  <span className="block lg:hidden text-transparent  bg-blend-overlay bg-clip-text bg-gradient-to-r from-black/90 via-black/80 to-black/60  ">for animal hospitals</span>{' '}
                  <span className="block bg-blend-overlay bg-clip-text bg-gradient-to-br from-black via-black/90 to-black/80 ">designed to make humans happy.</span>
                </h1>
                <p className="mt-3 max-w-md mx-auto text-normal text-gray-800 text-xl md:mt-5 md:max-w-3xl">
                  Meet Milo: the first truly all-in-one software solution for veterinary practices. After three years of carefully building Milo inside of our former hospitals, we're making our technology available to everyone.
                </p>
                <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-3xl shadow-xl">
                    <PrimaryButton copy="Get a demo" href="https://calendly.com/milo-demo/30min"  onClick={() => ReactGA.event({ category: 'DEMO', action: 'Schedule Demo', label: 'Home Page Hero' })}/>

  

                  </div>
                  <div className="p-2" />
                  <div className="rounded-3xl shadow-xl">
                    <SecondaryButton copy="Feature tour" href="../features" />
                  </div>
                  
                </div>
                
              </div>
              
            </div>
            <div className="relative w-full h-96 sm:h-48 md:h-64 lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/5 xl:w-1/2 lg:h-2/3 xl:h-full 2xl:max-w-4xl" >
              <img
                className="absolute  top-0 lg:top-8 w-full h-3/4 object-cover object-top lg:object-left z-50 xl:hidden  "
                src={HomepageHeroScreenshots}
                alt="Screenshots of Milo Software"
              />
              <img
                className=" hidden xl:flex  transform   xl:mx-8  absolute top-0 lg:top-16    "

                src={HomepageHeroScreenshotsxl}
                alt="Screenshots of Milo Software"
              />
            </div>
            <img className="hidden lg:block width-full pt-8 px-12 lg:w-4/5 mx-auto xl:-mt-24 2xl:w-3/5" src={Team} />
          </main>
        </div>
      </div>
    </div >
  )
}

export default HeroSection;
