import { createGlobalStyle } from 'styled-components';
import { TEXT_PRIMARY } from './colors'

const BaseStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 18px;
    height: 100%;
    color: ${TEXT_PRIMARY};
  }

  body {
    position: relative;
    line-height: 1;
    min-height: 100%;
    background: white;
    margin: 0;
  }

  *,
  *::before,
  *::after {
    // font-family: Oxygen, monospace, //'Open Sans', 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    color: inherit;
  }

  #root {
    min-height: 100vh;
    position: relative;
  }
`;

export default BaseStyle;
