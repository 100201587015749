import React from 'react';
import HeroSection from './sections/hero';
import Integrations from './sections/integrations';

import FeatureList from '../../components/sections/feature-list';
import HomepageCollar from '../../assets/images/homepage_collar.png';
// import PrimaryButton from '../../components/primary-button';
import SecondaryButton from '../../components/secondary-button';
import FooterMain from '../../components/footer';
import ReactGA from 'react-ga';

const Features = () => {
  return (
    <div className="bg-white overflow-hidden">
      <HeroSection />
      <a name="list" />

      <Integrations />

      <FeatureList />
      <div className="relative bg-white bg-left bg-no-repeat bg-cover xl:bg-contain" style={{ backgroundColor: '#D6B7F2', backgroundImage: `url(${HomepageCollar})` }}>
        <div className="mx-auto max-w-md pt-16 pb-20 px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-5xl">
          <p className="mt-2 text-6xl font-extrabold text-white tracking-tight uppercase font-condensed">
            Ready to make the switch?
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-center">
            <div className="rounded-md  pb-2 sm:pb-0 sm:pr-2">
              <SecondaryButton copy="Schedule a Live Demo" href="https://calendly.com/milo-demo/30min" onClick={() => ReactGA.event({ category: 'DEMO', action: 'Schedule Demo', label: 'Feature Footer' })}/>
            </div>
            <div className="rounded-md ">
              <SecondaryButton copy="Contact us" href="/contact" />
            </div>
            {/* <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <SecondaryButton copy="Feature tour" />
          </div> */}
          </div>
        </div>
      </div>
      <FooterMain />
    </div>
  )
}

export default Features;
