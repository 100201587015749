import React from "react";
import ReactGA, { ga } from 'react-ga';
ReactGA.initialize('UA-171076918-2', { gaOptions: { siteSpeedSampleRate: 100 } });
const CtaLink = ({ copy, href = 'mailto:hello@getmilo.com', mttop = 'mt-5 ' }) => {
  return (
    <p className={mttop + " max-w-prose mx-auto"}>
      <a href={href} className="text-purple">{copy}→</a>
      {/* <a href={href} onClick={console.log('hello')} className="text-purple">{copy}→</a> */}
    </p>
  );
}

export default CtaLink;
