import React from 'react';

import CtaLink from '../../../components/cta-link';

const MiddleCopy = () => {
  return (
    <div className="relative bg-white pt-0 z-40 lg:pt-24">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-3xl">
        <p className="text-left lg:text-center mt-2 text-6xl font-extrabold text-gray-900 tracking-tight uppercase font-condensed">
          The Journey is Just as important as the destination
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          The first of Milo’s core values is a simple one. Standing at the top of the mountain isn’t that fun if you hated the hike to get there.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          If it takes us 10 years to achieve our mission, it is those 10 years that matter most. Achieving something great and enjoying the journey to get there do not have to be mutually exclusive.
        </p>
        <CtaLink copy="See Milo’s other 10 core values" href="#corevalues" mttop="mt-2 text-xl" />
      </div>
    </div>
  );
};

export default MiddleCopy;
