import React from 'react';
import PrimaryButton from '../../../components/primary-button';
import SecondaryButton from '../../../components/secondary-button';
import Nav from '../../../components/nav/black2';




const HeroSection = () => {
  return (
    <div className="">
      <div className="relative bg-white ">

        <div className="
          absolute left-[-50%] w-[200%] right-[0] bg-pink-200 overflow-hidden
          top-36
          h-48 sm:h-56 xl:h-72 "
          style={{

            transform: 'rotate(-9deg)'
          }} />



        <div className="relative pt-6 sm:pb-10">
          <Nav />

          {/* <main className="lg:relative">


            <div aria-hidden="true" className="hidden absolute  w-full h-6 bottom-0" />

            <div className="flex flex-col max-w-7xl mx-auto pt-16 pb-2  text-left items-start px-4 sm:px-8 xl:pr-16  ">
              <h1 className="flex flex-col font-extrabold tracking-tight text-black/80 text-4xl sm:text-5xl lg:text-6xl xl:text-7xl max-w-7xl ">
                <div>We're ready whenever you are.</div>
              </h1>




               <p className="mt-4 text-xl pt-4 max-w-1/2 text-center">
                <CtaLink copy=" View our full list of 500+ features here" href="mailto:hello@getmilo.com" mttop="mt-2 text-xl" /> 
          Below is just a sampling of the innovative features that make Milo standout from the pack.
          </p> 
            </div>



          </main> */}
        </div>
      </div >
    </div >
  )
}

export default HeroSection;
