import React from 'react';

import Evan from '../../../assets/images/careers/evan.jpg';
import Logos from '../../../assets/images/careers/logos2.png';

const IndustrySection = () => {
  return (
    <div className="relative pt-16 pb-16 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-0 -mr-32 md:pl-36 md:-mr-12 lg:px-2 lg:m-0 lg:relative lg:h-full">
              <div className="text-left">
              <img
                className="w-[675px] px-4 md:pl-0 md:height-[600px]  lg:right-0 xl:h-4/5 lg:w-auto md:max-w-xl lg:max-w-none"
                src={Evan}
                alt=""
              />
              <div className="pt-4 pb-12 pl-8 text-xs lg:pl-0">
                <p>Evan, our CEO, presenting a draft of the schema.</p>
              </div>
              </div>
            </div>
          </div>
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-0 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div className="mt-0">
                <h2 className="text-6xl font-extrabold tracking-tight text-gray-900 uppercase font-condensed">
                  The veterinary industry has not been known for its technology, until now
                </h2>
                <p className="mt-4 text-lg text-black">
                  We’ve assembled a great team of software engineers with varying experience levels from across the world. We prioritize mentorship and learning by experimenting with and implementing the latest in modern web development technologies.
                </p>
                <p className="mt-4 text-lg text-black">
                  Don’t worry, we have a real CTO; it turns out Evan just has a talent for drawing up schemas on a paper bag.
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndustrySection;
