import React from 'react';

import CtaLink from '../../../components/cta-link';

const MiddleCopy = () => {
  return (
    <div className="relative bg-white pt-8 md:pt-0">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-3xl">
        {/* <h2 className="text-base font-semibold tracking-wider text-purple uppercase">Deploy faster</h2> */}
        <p className="text-left  text-5xl lg:text-center mt-2 lg:text-6xl font-extrabold text-gray-900 tracking-tight uppercase font-condensed">
          Everything you<br />
          need to run a <br />
          veterinary<br />
          hospital,<br />
          in one<br />
          tab.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          Milo allows your staff to do everything from one place. The Milo OS has over 50 modules of functionality and new ones are added every month. The best part? Every new module is included in your subscription, at no extra charge.
        </p>
        <CtaLink copy="Get a tour of Milo’s all-in-one software" href="./../features" mttop="mt-2 text-xl" />
      </div>
    </div>
  );
};

export default MiddleCopy;
