import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from '../pages/home';
import AboutUs from '../pages/about-us';
import Pricing from '../pages/pricing';
import Terms from '../pages/terms';
import Features from '../pages/features';
import Contact from '../pages/contact';
import BaseStyle from '../styles/base';
import Careers from '../pages/careers';
import Demo from '../pages/demo';
import Content from '../pages/content';
import Integrations from '../pages/integrations';
import PostVmx from '../pages/lander/post-vmx';
import RouteChangeTracker from '../components/routechangetracker/'

const App = () => {
  return (
    <>
      <BaseStyle />
      
      <Router>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/pricing">
            <Pricing />
          </Route>
          <Route exact path="/about-us">
            <AboutUs />
          </Route>
          <Route path="/features">
            <Features />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/careers">
            <Careers />
          </Route>
          <Route path="/demo">
            <Demo />
          </Route>
          <Route path="/content">
            <Content />
          </Route>
          <Route path="/integrations">
            <Integrations />
          </Route>
          <Route path="/lander/post-vmx">
            <PostVmx />
          </Route>
        </Switch>
        <RouteChangeTracker />
      </Router>
      
    </>
    

  );
}

export default App;
