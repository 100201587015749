import React from 'react';
// /* This example requires Tailwind CSS v2.0+ */
// import { AnnotationIcon, ChatIcon, CloudIcon, TrendingUpIcon, UserGroupIcon } from '@heroicons/react/outline';
// import CalendarPreview from '../../../assets/images/features/calendar-w-preview.png';
import sections from './features.js'





export default function Example() {
  return (
    <div className=" bg-gray-50 overflow-hidden ">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 pb-20 lg:max-w-screen-xl">

        <div className="mx-auto max-w-md px-4 pt-12 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-3xl">
          <p className="text-left lg:text-center mt-2 text-6xl font-extrabold text-black/80 tracking-tight uppercase font-condensed">
            Just a few of Milo's most popular features
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-black/80 text-left">
            Listed below are just a handful of the over 500+ features found in Milo. Some of these features will be familiar as you have them in the software packages you use today. Many more are not. Instead they are the features you always wished existed but never did, until now.
          </p>
        </div>



        {/* Header centered */}
        {/* 
        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            One window to rule them all
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Let's be honest, veterinary software is a mess. The average staff members has over four windows open at a time. Not only does this slow them down, it leads to mistakes and frustrates your team and customers. That's why Milo takes an all-in-one approach to simplify your technology.
          </p>
        </div> */}




        {/*alternate left right features*/}

        {sections.map((item) => (
          <fragment key={item.id} className="relative">


            {item.leftimg === true ? (
              // left side img
              <>
                {/* < svg
                  className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-1/4"
                  width={404}
                  height={784}
                  fill="none"
                  viewBox="0 0 404 784"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={500} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                </svg> */}
                <div className="relative my-16 pt-4 pb-18 lg:my-24">
                  <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-5 lg:gap-8 lg:items-start">
                    <div className="lg:col-start-4 lg:col-span-2">
                      <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">{item.title}</h3>
                      <p className="mt-3 text-lg text-gray-500">
                        {item.description}
                      </p>

                      <dl className="mt-10 space-y-16">
                        {item.features.map((item) => (
                          <div key={item.id} className="relative">
                            <dt>
                              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#fecc15] text-white">
                                <item.icon className="h-6 w-6" aria-hidden="true" />
                              </div>
                              <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                            </dt>
                            <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                          </div>
                        ))}
                      </dl>
                    </div>

                    <div className="mt-10 relative lg:mt-0 lg:col-start-1 lg:col-span-3">
                      {/* <svg
                        className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                        width={784}
                        height={404}
                        fill="none"
                        viewBox="0 0 784 404"
                        aria-hidden="true"
                      >
                        <defs>
                          <pattern
                            id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                          >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                          </pattern>
                        </defs>
                        <rect width={784} height={250} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                      </svg> */}
                      <img
                        className="relative mx-auto rounded-lg shadow-2xl md:-ml-8"

                        src={item.img}
                        alt=""
                      />
                    </div>
                  </div>

                </div>
              </>

            ) : (

              // right side img
              <>

                <div className="relative my-16 lg:my-24 pt-4 pb-20 lg:grid lg:grid-cols-5 lg:gap-8 lg:items-start">
                  <div className="relative col-span-2">
                    <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                      {item.title}
                    </h3>
                    <p className="mt-3 text-lg text-gray-500">
                      {item.description}
                    </p>

                    <dl className="mt-10 space-y-16">
                      {item.features.map((item) => (
                        <div key={item.id} className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#fecc15] text-white">
                              <item.icon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                          </dt>
                          <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                  {/* < svg
                    className="hidden lg:block absolute left-full transform -translate-x-1/2 translate-y-32"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                      </pattern>
                    </defs>
                    <rect width={404} height={500} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                  </svg> */}

                  <div className="mt-10 relative lg:mt-0 col-span-3" aria-hidden="true">
                    {/* <svg
                      className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                      width={784}
                      height={404}
                      fill="none"
                      viewBox="0 0 784 404"
                    >
                      <defs>
                        <pattern
                          id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                          x={0}
                          y={0}
                          width={20}
                          height={20}
                          patternUnits="userSpaceOnUse"
                        >
                          <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                      </defs>
                      <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                    </svg> */}
                    <img
                      className={item.imgwidth + " relative mx-auto md:ml-8 rounded-lg shadow-2xl"}
                      // width={item.imgwidth}
                      src={item.img}
                      alt=""
                    />
                  </div>
                </div>
              </>
            )}

          </fragment>

        ))}

      </div>
    </div >
  )
}
