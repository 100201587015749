import React from 'react';

import CtaLink from '../../../components/cta-link';

import HomepageTeam from '../../../assets/images/homepage_team.png';

const TheTeam = () => {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-16 bg-beige">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-5xl">
        <p className="text-center mt-2 text-6xl font-extrabold text-gray-900 tracking-tight uppercase font-condensed">
          Meet the team
        </p>
        <div className="mt-12 pb-8">
          <img src={HomepageTeam} alt="A series of photos showing the faces of the Milo team" className="m-auto" style={{ maxHeight: "280px" }} />
        </div>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          You can get a sense of who you’d be working with by checking out our About Us page! You can read short bios on the team and a little bit about what each of us does.
        </p>
        <CtaLink copy="Meet the team" href="./about-us" mttop="mt-2 text-xl" />
      </div>
    </div>
  );
};

export default TheTeam;
