import React from 'react';
import HeroSection from './sections/hero';
import FooterMain from '../../components/footer';
import PricingPercentage from '../../components/sections/pricing-percent';
import ReactGA from 'react-ga';

const PricingPage = () => {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <HeroSection />



      <PricingPercentage />
      {/* <Pricing /> */}
      {/* <PricingFaq /> */}
      <p className="  p-4 text-xs">*Only available on Milo All-In-One Plus subscription.</p>
      <p className="  p-4 text-xs">**Volume discounting available. You may use your existing payment processor but may lose functionality within software as a result.</p>
      <FooterMain />
    </div>

  )
}

export default PricingPage;
