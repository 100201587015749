import React from 'react';


import { CheckIcon } from '@heroicons/react/outline'

import Video from '../../../assets/images/careers/demo.mp4';
const features = [

    {
        name: 'Are deeply empathetic and open to new ideas.',
        description: ' ',
      },
      {
        name: 'Are willing to work hard and smart enough to take breaks.',
        description: ' ',
      },
      {
        name: 'Are intellectually curious and honest.',
        description: ' ',
      },
      {
        name: 'Embrace humility and reject egotism.',
        description: ' ',
      },
  {
    name: 'Listen attentively to our customers, fellow employees, communities and shareholders.',
    description: ' ',
  },
  {
    name: 'Assume positive intent and believe that people will strive to bring their best to the table.',
    description: ' ',
  },


  {
    name: 'Understand that part of growth is delivering and accepting feedback.',
    description: ' ',
  },

  

  {
    name: 'Win as a team. We compete with the external world, not amongst each other.',
    description: ' ',
  },


  {
    name: 'Lean into our strengths as individuals, and recognize that the different strengths of others should be celebrated and not compared.',
    description: ' ',
  },
  {
    name: 'Value the journey as much as the destination. How we do what we do matters as much as, if not more than, the outcome.',
    description: ' ',
  },
  {
    name: 'Regularly question and adapt our ideas and core values to ensure they are the right ones.',
    description: ' ',
  },
]

export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-6xl font-extrabold tracking-tight text-gray-900 uppercase font-condensed">
                  See What you'll be building
                </h2>
          <p className="mt-4 pb-2 text-lg text-gray-500">
          We're proud of our product at Milo. Here's a short 30-second demo to give you a sense of what you'll be working on...
          </p>
        </div>
        <div> 
        <video src={Video} controls>Your browser does not support video</video>

        </div>

      </div>
    </div>
  )
}