import React, { Fragment } from 'react';

import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon, ArrowSmRightIcon } from '@heroicons/react/outline';
import ReactGA from 'react-ga';
// import Logo from '../../assets/images/logo_white.png';
// import LogoBlack from '../../assets/images/logo_black.png';

const NAV_LINKS = [
  { name: 'Features', href: '/features' },
  { name: 'Pricing', href: '/pricing' },
  // { name: 'Why Milo?', href: '#' },
  // { name: 'Switching', href: '#' },
  //{ name: 'MiloCares™', href: '#' },
  { name: 'About Us', href: '/about-us' },
  { name: 'Contact Us', href: '/contact' },
  { name: 'Careers', href: '/careers' },
]

const Nav = () => {
  return (
    <Popover>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 ">
        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
          <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/">
                <span className="sr-only">Milo logo</span>
                <svg className="text-black hover:opacity-50" width="60" height="21" viewBox="0 0 74 28" fill="black" xmlns="http://www.w3.org/2000/svg" color="hsl(0, 0%, 100%)">
                  <path d="M32.1437 10.3257C32.2444 9.45882 32.7311 8.59195 33.5031 8.59195C34.2751 8.59195 35.1814 10.1003 35.7016 10.8805C37.46 13.896 38.5224 17.2894 38.8064 20.7975C39.0078 23.6235 38.3365 27.091 35.0303 27.3164C33.8791 27.4386 32.7666 26.847 32.1941 25.8081C30.3647 22.6006 32.1101 10.5337 32.1437 10.3257Z" fill="currentColor"></path>
                  <path d="M35.8694 2.80113C36.0266 3.91653 35.5894 5.03282 34.7256 5.72143C33.8618 6.41003 32.7056 6.56393 31.7011 6.12402C30.6965 5.68412 29.9996 4.71879 29.878 3.59866C29.7208 2.48326 30.1581 1.36697 31.0219 0.678363C31.8857 -0.0102412 33.0418 -0.164134 34.0463 0.275769C35.0509 0.715672 35.7478 1.681 35.8694 2.80113Z" fill="currentColor"></path>
                  <path d="M70.2401 17.5728C70.2401 30.5759 52.031 30.5759 52.031 17.5728C52.031 4.56972 70.2401 4.56972 70.2401 17.5728Z" fill="currentColor"></path>
                  <path d="M50.2521 27.0737C38.1854 30.4198 39.8469 1.24085 44.8145 0.00989032C47.1305 -0.562246 49.9164 23.8662 50.2521 27.0737Z" fill="currentColor"></path>
                  <path d="M4.73773 0.859469C5.17141 0.384298 5.86045 0.270406 6.41599 0.582069C8.74878 1.72634 12.7095 8.80003 14.2535 16.3418C14.2535 16.0644 16.7876 2.19445 19.2883 0.547394C20.7316 -0.440841 22.3763 2.02108 22.9804 3.04399C28.1327 11.9034 29.7942 24.0917 27.495 26.5709C27.104 27.0029 26.557 27.2478 25.9845 27.2471C21.9063 27.2471 20.7819 15.1109 20.9497 12.2502C20.6141 14.6254 18.0631 27.3338 14.371 27.3338C10.6788 27.3338 7.77539 15.1976 7.50686 12.2502C7.69147 14.7988 6.78521 27.2471 2.47208 27.2471C0.961647 27.2471 0.525299 25.4093 0.391038 24.1784C-0.196353 18.4397 2.37139 3.7895 4.73773 0.859469Z" fill="currentColor"></path>
                </svg>
              </a>
              <div className="-mr-2 flex items-center md:hidden">
                <Popover.Button className="bg-white opacity-90 backdrop-blur-lg rounded-md p-1.5 inline-flex items-center justify-center text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-violet-500">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-5 w-5" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="hidden md:flex md:space-x-10">
            {NAV_LINKS.map((item) => (
              <a key={item.name} href={item.href} className=" font-medium text-md text-black hover:opacity-70">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
            <span className="inline-flex rounded-3xl shadow">
              <a
                href="https://fluffy.demo.milo.vet/"
                className="inline-flex items-center px-4 py-1.5 text-base  rounded-3xl text-black text-medium text-sm bg-white bg-opacity-20 hover:bg-opacity-40 hover:shadow-xl"
              >
                Sign in <ArrowSmRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </span>
          </div>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <svg width="80" height="28" viewBox="0 0 74 28" fill="black" xmlns="http://www.w3.org/2000/svg" color="hsl(0, 0%, 100%)">
                  <path d="M32.1437 10.3257C32.2444 9.45882 32.7311 8.59195 33.5031 8.59195C34.2751 8.59195 35.1814 10.1003 35.7016 10.8805C37.46 13.896 38.5224 17.2894 38.8064 20.7975C39.0078 23.6235 38.3365 27.091 35.0303 27.3164C33.8791 27.4386 32.7666 26.847 32.1941 25.8081C30.3647 22.6006 32.1101 10.5337 32.1437 10.3257Z" fill="currentColor"></path>
                  <path d="M35.8694 2.80113C36.0266 3.91653 35.5894 5.03282 34.7256 5.72143C33.8618 6.41003 32.7056 6.56393 31.7011 6.12402C30.6965 5.68412 29.9996 4.71879 29.878 3.59866C29.7208 2.48326 30.1581 1.36697 31.0219 0.678363C31.8857 -0.0102412 33.0418 -0.164134 34.0463 0.275769C35.0509 0.715672 35.7478 1.681 35.8694 2.80113Z" fill="currentColor"></path>
                  <path d="M70.2401 17.5728C70.2401 30.5759 52.031 30.5759 52.031 17.5728C52.031 4.56972 70.2401 4.56972 70.2401 17.5728Z" fill="currentColor"></path>
                  <path d="M50.2521 27.0737C38.1854 30.4198 39.8469 1.24085 44.8145 0.00989032C47.1305 -0.562246 49.9164 23.8662 50.2521 27.0737Z" fill="currentColor"></path>
                  <path d="M4.73773 0.859469C5.17141 0.384298 5.86045 0.270406 6.41599 0.582069C8.74878 1.72634 12.7095 8.80003 14.2535 16.3418C14.2535 16.0644 16.7876 2.19445 19.2883 0.547394C20.7316 -0.440841 22.3763 2.02108 22.9804 3.04399C28.1327 11.9034 29.7942 24.0917 27.495 26.5709C27.104 27.0029 26.557 27.2478 25.9845 27.2471C21.9063 27.2471 20.7819 15.1109 20.9497 12.2502C20.6141 14.6254 18.0631 27.3338 14.371 27.3338C10.6788 27.3338 7.77539 15.1976 7.50686 12.2502C7.69147 14.7988 6.78521 27.2471 2.47208 27.2471C0.961647 27.2471 0.525299 25.4093 0.391038 24.1784C-0.196353 18.4397 2.37139 3.7895 4.73773 0.859469Z" fill="currentColor"></path>
                </svg>
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-violet-500">
                  <span className="sr-only">Close main menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              {NAV_LINKS.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <a
              href="https://fluffy.demo.milo.vet/"
              className="flex w-full px-3 py-3 text-center items-center justify-center my-auto font-medium text-purple bg-gray-50 hover:bg-gray-100 hover:text-text-purple-darker"
            >
              Sign in <ArrowSmRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default Nav;
