import React from 'react';

import CtaLink from '../../cta-link';

const LeftRightScreenshot = ({ title, copy = [], screenshot, screenshotAlt = "", ctaCopy, ctaHref, quote, quoteAuthor }) => {
  return (
    <div className="relative pt-16 pb-32 overflow-hidden bg-beige">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div className="mt-6">
                <h2 className="text-5xl lg:text-6xl font-extrabold tracking-tight text-gray-900 uppercase font-condensed">
                  {title}
                </h2>
                {
                  copy.map((c) => (
                    <p className="mt-4 text-lg text-black">
                      {c}
                    </p>
                  ))
                }
                <CtaLink copy={ctaCopy} href={ctaHref} mttop="mt-2 text-xl" />
              </div>
            </div>
            {/* <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-black">
                    &ldquo;{quote}&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">{quoteAuthor}</div>
                  </div>
                </footer>
              </blockquote>
            </div> */}
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-12 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-3/5 sm:pl-32 md: pl-0 lg:w-full lg:h-auto lg:absolute lg:right-0 xl:h-4/5 xl:w-auto md:max-w-xl lg:max-w-none"
                src={screenshot}
                alt={screenshotAlt}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftRightScreenshot;
