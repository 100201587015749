import React from 'react';

import CtaLink from '../../../components/cta-link';

const FACTS = [
  { stat: "99.3%", detail: "of patients who visit our hospital actively communicate via Milo texting" },
  { stat: "53%", detail: "reduction in phone volume compared to hospitals not using Milo texting" },
  { stat: "300,000+", detail: "patient records maintained in our software" },
  { stat: "200+", detail: "active users logging into our software everyday" },
  { stat: "$20M+", detail: "payments processed in our software over the last year." },
  { stat: "99.7%", detail: "of clients maintain active credit cards on file with us" },
  { stat: "99.999%", detail: "recorded uptime over the past 12 months" },
  { stat: "1 Million+", detail: "Milo automations that have saved staff time" },
  { stat: "2,000+", detail: "new patients acquired in the first 12 months our new hospital opened" },
]

const MiddleFacts = () => {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-16">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-6xl">
        <p className="text-left lg:text-center mt-2 text-5xl lg:text-6xl font-extrabold text-gray-900 tracking-tight uppercase font-condensed">
          We do more than make your team happy...
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          We’ll save you from the subjective and dive right into the vitals. In fact, we pulled these numbers from our own hospitals that have used Milo software.
        </p>
        <div className="my-12 max-w-4xl m-auto">
          <div className="grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-3">
            {FACTS.map((facts) => (
              <div key={facts.detail} className="pt-6">
                <div className="flow-root rounded-lg px-6 pb-2">
                  <div className="text-left">
                    <h3 className="mt-2 text-5xl font-medium text-purple tracking-tight">{facts.stat}</h3>
                    <p className="mt-2 text-base text-gray-500">
                      {facts.detail}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <CtaLink copy="See what is included in your subscription" href="./../features" mttop="mt-2 text-xl" />
      </div>
    </div>
  );
};

export default MiddleFacts;
