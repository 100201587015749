import React from 'react';

import HomepageCollar from '../../../assets/images/homepage_collar.png';
import SecondaryButton from '../../../components/secondary-button';
import ReactGA from 'react-ga'
const HiringFooter = () => {
  return (
    <div className="relative bg-white bg-left bg-no-repeat bg-cover xl:bg-contain" style={{ backgroundColor: '#D6B7F2', backgroundImage: `url(${HomepageCollar})` }}>
      <div className="mx-auto max-w-md py-16 px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-5xl">
        <p className="mt-2 text-6xl font-extrabold text-white tracking-tight uppercase font-condensed">
          We’re hiring!
        </p>
        <div className="mt-10 sm:flex sm:justify-center lg:justify-center">
          <div className="rounded-3xl shadow-xl">
            <SecondaryButton copy="See Open Roles" href="https://jobs.getmilo.com" onClick={() => ReactGA.event({ category: 'JOBS', action: 'See Jobs', label: 'Jobs Footer' })}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HiringFooter;
