import React from 'react';
import ReactGA from 'react-ga'
import HeroSection from './sections/hero';
import MiddleCopy from './sections/journey';
import FooterMain from '../../components/footer';
import TeamCarousel from './sections/team-gallery';
import Demo from './sections/demo';
import Stack from './sections/stack';
import CoreValues from './sections/corevalues';
import LeftRightScreenshotVariation from './sections/productowners';
import TheTeam from './sections/the-team';
import HiringFooter from './sections/hiring-footer';
import LeftRightScreenshot from '../../components/sections/left-right-screenshot-careers';
import IndustrySection from './sections/industry';
import Zoom from '../../assets/images/careers/zoom.png';


const HomePage = () => {
  return (
    <div className="bg-white overflow-hidden">

      <HeroSection />
      <MiddleCopy />
      <TeamCarousel />

      <LeftRightScreenshot
        title="We’re figuring out this post-COVID world just like everyone else."
        copy={
          [
            "While we don’t have all the answers, we’re working hard to figure them out.",
            "Even before COVID, we had a remote-first culture so the transition for us was easy. Like other software companies, embracing remote work makes a lot of sense for our business.",
            "At Milo, we’re committed to remote work for the forseeable future. Rather than investing in an office, we invest in a handful of team retreats and meetups per year.",
            "Our hubs like NY, Denver, and LA enjoy more frequent facetime for locals in addition to the couple of company-wide meetups we organize each year. In-person meetings from time to time remain critical (when it is safe to do so) but we don’t see the need for a trip to the office five days a week.",
            "In the future, we hope to establish flexible office setups in areas where our employees are concentrated for those who enjoy the camaraderie."
          ]
        }
        
        ctaCopy="Join our team"
        ctaHref="http://jobs.getmilo.com" onClick={() => ReactGA.event({ category: 'JOBS', action: 'See Jobs', label: 'Post COVID Copy Job Link' })}
        screenshot={Zoom}
      />
      <IndustrySection />
      <Stack />
      <Demo />
      <LeftRightScreenshotVariation />
      <CoreValues />
      <TheTeam />
      <HiringFooter />
      <FooterMain />
    </div>
  )
}

export default HomePage;


