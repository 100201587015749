import React from "react";


export default function ColorBlend() {
  return (


    <div className=" absolute inset-y-0 h-full w-full" aria-hidden="true">
      <div className="relative h-full sm:w-full transform scale-150 -mx-96 ">
        <div className="
          // angled trapezoid container
            relative 
            max-w-full
             bg-gradient-radial from-yellow-darker to-orange-500
            transform
            mx-auto
            -rotate-12
            -mt-56
            w-screen
            scale-150
            overflow-hidden

            h-[450px]
            lg:h-[460px]
            xl:h-[425px]
            
            ">


          <div className="
            absolute   
            animate-rollup
            bg-gradient-to-tl  from-yellow via-red-300 to-white
            opacity-90
            transform
            delay-5000
            -rotate-90
            h-[500px]
            rounded-lg
            blur-2xl
            blend-mode-overlay
            w-[500px]
            scale-100
            "> </div>

          <div className="
            absolute   
            animate-rollup
            bg-gradient-to-tl  from-yellow to-white
            opacity-90
            transform
            delay-5000
            -rotate-90
            h-[2000px]
            rounded-lg
            blur-2xl
            bg-blend-color-dodge
            w-[2000px]
            scale-100
            "> </div>

          <div className="
            absolute   
            animate-rollup
            bg-gradient-to-bl  from-yellow-500 to-red-800
            opacity-90
            transform
       
            -rotate-270
            h-[2000px]
            rounded-lg
            blur-2xl
            bg-blend-color-dodge
            w-[500]
            scale-100
            "> </div>

          <div className="
            absolute   
            animate-rollup
            bg-gradient-to-tr  from-pink-600 
            opacity-80
            transform
            delay-1000
            -rotate-90
            h-[2000px]
            rounded-lg
            blur-2xl
            bg-blend-color-dodge
            w-[2000px]
            scale-100
            "> </div>
          <div className="
            absolute   
            animate-rollup
            bg-gradient-to-bl  from-pink-600 
            opacity-80
            transform
          
            -rotate-130
            h-[500px]
            rounded-lg
            blur-2xl
            bg-blend-color-dodge
            w-[2000px]
            scale-90
            "> </div>
          <div className="
            absolute   
            animate-rollup
            bg-gradient-to-bl  from-orange-800 
            opacity-80
            transform
          
            -rotate-130
            h-[100px]
            rounded-lg
            blur-2xl
            bg-blend-color-dodge
            w-[2000px]
            scale-90
            "> </div>
          <div className="
            absolute   
            animate-rollup
            bg-gradient-to-tl  from-yellow-300 via-orange-300 to-white
            opacity-90
            transform
            delay-0
            -rotate-90
            h-[500px]
            rounded-lg
            blur-2xl
            bg-blend-color-burn
            w-[500px]
            scale-100
            "> </div>

          <div className="
            absolute   
            animate-rollup
            bg-gradient-to-tl  from-yellow-400 via-orange-500 to-white
            opacity-90
            transform
            delay-1000
            -rotate-180
            h-[2000px]
            rounded-lg
            blur-2xl
            bg-blend-hue
            w-[500px]
            scale-100
            "> </div>
          <div className="
            absolute   
            animate-rollup
            bg-gradient-to-tl  from-red-400 via-white to-pink-300
            opacity-90
            transform
            h-[2000px]
            rounded-full
            blur-md
            blend-mode-overlay
            w-[2000px]
            scale-100
            "> </div>
          <div className="
            absolute   
            animate-roll
            bg-gradient-to-tl  from-white
            opacity-40
            transform
            delay-1000
            h-[2000px]
            rounded-full
            blur-md
            blend-mode-overlay
            w-[2000px]
            scale-100
            "> </div>
          {/* <div className="
    absolute 
    bg-gradient-tr
    from-red-400
    via-white 
    to-purple-900

    transform
    animate-roll
    opacity-90
    blend-mode-overlay
    h-[1600px]
    rounded-md
    blur-md
    w-[2100px]
     ">  </div> */}
          <div className="
    absolute 
    bg-gradient-to-bl  from-yellow-200 to-white
    transform
    rounded-full
    opacity-70
    
    h-full
    w-full
    scale-150
     "> </div>

        </div>



      </div>
    </div>
  )
}
