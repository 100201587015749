import React from 'react';


import { CheckIcon } from '@heroicons/react/outline'

import Stack1 from '../../../assets/images/careers/stack1.png';
import Stack2 from '../../../assets/images/careers/stack2.png';
import Stack3 from '../../../assets/images/careers/stack3.png';
import Stack4 from '../../../assets/images/careers/stack4.png';
import Stack5 from '../../../assets/images/careers/stack5.png';
import Stack6 from '../../../assets/images/careers/stack6.png';
import Stack7 from '../../../assets/images/careers/stack7.png';
import Stack8 from '../../../assets/images/careers/stack8.png';
import Stack9 from '../../../assets/images/careers/stack9.png';
import Stack10 from '../../../assets/images/careers/stack10.png';
import Stack11 from '../../../assets/images/careers/stack11.png';
import Stack12 from '../../../assets/images/careers/stack12.png';
import Stack13 from '../../../assets/images/careers/stack13.png';
import Stack14 from '../../../assets/images/careers/stack14.png';
import Stack15 from '../../../assets/images/careers/stack15.png';
import Stack16 from '../../../assets/images/careers/stack16.png';
import Stack17 from '../../../assets/images/careers/stack17.png';
import Stack18 from '../../../assets/images/careers/stack18.png';

const features = [

    {
        name: 'Apollo',
        description: Stack1,
      },
      {
        name: 'React',
        description: Stack2,
      },
      {
        name: 'Tailwind',
        description: Stack3,
      },
      {
        name: 'Tailwind',
        description: Stack4,
      },
      {
        name: 'Tailwind',
        description: Stack5,
      },
      {
        name: 'Tailwind',
        description: Stack6,
      },
      {
        name: 'Tailwind',
        description: Stack7,
      },
      {
        name: 'Tailwind',
        description: Stack8,
      },
      {
        name: 'Tailwind',
        description: Stack9,
      },
      {
        name: 'Tailwind',
        description: Stack10,
      },
      {
        name: 'Tailwind',
        description: Stack11,
      },
      {
        name: 'Tailwind',
        description: Stack12,
      },
      {
        name: 'Tailwind',
        description: Stack13,
      },
      {
        name: 'Tailwind',
        description: Stack14,
      },
      {
        name: 'Tailwind',
        description: Stack15,
      },
      {
        name: 'Tailwind',
        description: Stack16,
      },
      {
        name: 'Tailwind',
        description: Stack17,
      },
      {
        name: 'Tailwind',
        description: Stack18,
      },

      
]

export default function Example() {
  return (
    <div  className="bg-purple">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-6xl font-extrabold tracking-tight text-white uppercase font-condensed">
                  We take pride in our technology stack
                </h2>
                <p className="mt-4 pb-2 text-lg text-gray-100">
          We are constantly exploring emerging frameworks and adopting ones that are a fit for our team, approach and mission as a company.
          </p>
        </div>
        <div> 
        <dl className="mt-12 space-y-10 space-y-0 grid grid-cols-2 gap-x-6 gap-y-12 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative left">
              <dt>
                
                <img src={feature.description} className="w-[150px] mx-auto"/>
              </dt>
            
            </div>
          ))}
        </dl>
        </div>

      </div>
    </div>
  )
}