import React from 'react';
import HeroSection from './sections/heroalt';
import FooterMain from '../../components/footer';
import Team from '../../components/sections/team';
// import PricingFaq from '../../components/sections/pricing-faq';
import ReactGA from 'react-ga';
const AboutUs = () => {
  return (
    <div className="bg-white">
      <HeroSection />

      <Team />
      <FooterMain />
    </div>
  )
}

export default AboutUs;
