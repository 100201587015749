import React from 'react';

import CtaLink from '../../../components/cta-link';

import HomepageTeam from '../../../assets/images/homepage_team.png';

const TheTeam = () => {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-16 bg-beige">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-5xl">
        <p className="text-left lg:text-center mt-2 text-5xl lg:text-6xl font-extrabold text-gray-900 tracking-tight uppercase font-condensed">
          You’re already good with animals. Let Milo help you with humans.
        </p>
        <div className="mt-12 pb-8">
          <img src={HomepageTeam} alt="A series of photos showing the faces of the Milo team" className="m-auto" style={{ maxHeight: "280px" }} />
        </div>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          At Milo, we recognized early on that transforming the software used in the veterinary industry wasn’t a technical challenge, it was a people challenge.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          Recognizing the problem we needed to solve was half the battle. The other half is won with people. Our people to be specific.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-black text-left">
          Veterinary medicine and software aren’t just things you pick up by learning on your own (wouldn’t that be nice). That’s why Milo software comes with a dedicated customer success team to help you overcome all of the challenges that have prevented you from making a switch sooner.
        </p>
        <CtaLink copy="Meet the Milo team" href="./../about-us" mttop="mt-2 text-xl" />
      </div>
    </div>
  );
};

export default TheTeam;
