import React from 'react';
import PrimaryButton from '../../../components/primary-button';
import SecondaryButton from '../../../components/secondary-button';
import Nav from '../../../components/nav/black';




const HeroSection = () => {
  return (
    <div className="">
      <div className="relative bg-white ">

        <div className="
          absolute left-[-50%] w-[200%] right-[0] bg-[#Fecc15] overflow-hidden
          top-36
          h-48 sm:h-56 xl:h-72 "
          style={{

            transform: 'rotate(-9deg)'
          }} />



        <div className="relative pt-6 sm:pb-10">
          <Nav />

          <main className="lg:relative">


            {/* Overlapping background */}
            <div aria-hidden="true" className="hidden absolute  w-full h-6 bottom-0" />

            <div className="flex flex-col max-w-7xl mx-auto pt-16 pb-12  text-left items-start px-4 sm:px-8 xl:pr-16  ">
              <h1 className="flex flex-col font-extrabold tracking-tight text-black/80 text-4xl sm:text-5xl lg:text-6xl xl:text-7xl max-w-7xl pb-8 ">
                <div>See Milo in action.</div>
               


              </h1>
              
              <div style={{ position: 'relative', paddingBottom: '53.90625%', height: 0, width:'100%', }}>
                <iframe src="https://www.loom.com/embed/13c8753365b84c7cad59769cb9d20e81" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                  </iframe>
                </div>

              <p className="mt-4 sm:mt-8 text-lg md:text-lg  sm:pr-32 lg:pr-96 text-left ">
                Here is a 30-second overview of some of Milo's most powerful features such as integrated texting, VOIP and medical notes.
                <br></br>
              </p>



              <div className="mt-4 sm:mt-10 sm:justify-start sm:flex  lg:justify-start w-full">
                <div className="rounded-3xl shadow-xl">
                  <PrimaryButton copy="Book a demo" href="https://calendly.com/milo-demo/30min" />
                </div>
                <div className="p-2" />
                <div className="rounded-3xl shadow-xl ">
                  <SecondaryButton copy="Contact Us" href="/contact" />
                </div>
              </div>
              {/* <p className="mt-4 text-xl pt-4 max-w-1/2 text-center">
                <CtaLink copy=" View our full list of 500+ features here" href="mailto:hello@getmilo.com" mttop="mt-2 text-xl" /> 
          Below is just a sampling of the innovative features that make Milo standout from the pack.
          </p> */}
            </div>


          </main>
        </div>
      </div >
    </div >
  )
}

export default HeroSection;
