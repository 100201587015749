import React from 'react';
import PrimaryButton from '../../../components/primary-button';
import SecondaryButton from '../../../components/secondary-button';
import Nav from '../../../components/nav/black2';




const HeroSection = () => {
  return (
    <div className="">
      <div className="relative bg-white   ">
        {/* <div className="
          absolute left-[-50%] w-[200%] right-[0] bg-[#Fecc15] overflow-hidden
          top-[24%] sm:top-[12%] md:top-[15%] lg:top-[13%] xl:top-[16%]
          h-[19%] sm:h-[18%] md:h-[25%] "
          style={{
            // position: 'absolute',
            // top: '20%',
            // left: '-50%',
            // right: 0,
            // height: '25%',
            // width: '200%',
            // backgroundColor: '#Fecc15',
            transform: 'rotate(-9deg)'
          }} /> */}
        <div className="
          absolute left-[-50%] w-[200%] right-[0] bg-purple-200 overflow-hidden
          top-36
          h-48 sm:h-56 xl:h-72 "
          style={{
            // position: 'absolute',
            // top: '20%',
            // left: '-50%',
            // right: 0,
            // height: '25%',
            // width: '200%',
            // backgroundColor: '#Fecc15',
            transform: 'rotate(-9deg)'
          }} />



        <div className="relative pt-6 sm:pb-10 ">
          <Nav />

          <main className="lg:relative">


            {/* Overlapping background */}
            <div aria-hidden="true" className="hidden absolute  w-full h-6 bottom-0" />

            <div className="flex flex-col max-w-7xl mx-auto pt-16 pb-12 text-left items-start px-4 sm:px-8 xl:pr-16  ">
              <h1 className="flex flex-col  font-extrabold tracking-tight  text-4xl sm:text-5xl lg:text-6xl xl:text-7xl max-w-7xl ">
                <div>No      <span className="line-through">long-term agreements. </span></div>
                <div>No      <span className="line-through">training fees. </span></div>
                <div>No      <span className="line-through">data migration fees. </span></div>
                <div>No      <span className="line-through">customer support fees. </span></div>

              </h1>

              <div className="inline">
                <div className=" inline-block mt-4 sm:mt-8   text-black/80  font-extrabold text-4xl sm:text-5xl lg:text-6xl xl:text-7xl">One simple price for everything,&nbsp;
                  <div className="inline-block">
                    <div className="text-purple/80 inline-block">
                      forever*.

                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-4 sm:mt-8 text-lg md:text-lg  sm:pr-32 lg:pr-96 text-left ">
                Milo let's you replace multiple vendors with a simple monthly payment. If you're not ready to replace your PIMS, you can start smaller with a suite of our most popular products designed to integrate with your current software.
              </p>
              {/* <p className="mt-4 text-lg md:text-lg sm:pr-32 lg:pr-96   text-left ">
                Milo bundles all of your technology into one cohesive system. It's a PIMS, texting solution, phone system, customer portal and more.
              </p> */}



              <div className="mt-4 sm:mt-10 sm:justify-start sm:flex  lg:justify-start w-full">
                <div className="rounded-3xl shadow-xl">
                  <PrimaryButton copy="See pricing" href="#prices" />
                </div>

                <div className="p-2" />
                <div className="rounded-3xl shadow-xl ">
                  <SecondaryButton copy="See how you can save" href="#savings" />
                </div>
              </div>
              {/* <p className="mt-4 text-xl pt-4 max-w-1/2 text-center">
                <CtaLink copy=" View our full list of 500+ features here" href="mailto:hello@getmilo.com" mttop="mt-2 text-xl" /> 
          Below is just a sampling of the innovative features that make Milo standout from the pack.
          </p> */}
            </div>




            {/* 
            
            
                            <div className="relative max-w-3xl mx-auto pt-12 px-4 text-center sm:pt-16 sm:px-6 lg:max-w-7xl lg:px-8 ">
                    <h1 className="text-4xl font-extrabold tracking-tight text-black sm:text-5xl xl:text-6xl ">
                        <span className=" inline ">No </span>
                        <span className=" inline line-through">long-term agreements. </span>
                        <span className=" block  "> </span>
                        <span className=" block inline ">No </span>
                        <span className=" block inline line-through">upfront training or data fees. </span>
                        <span className=" block  "> </span>
                        <span className=" block inline ">No </span>
                        <span className=" block inline line-through">customer support fees. </span>
                        <span className=" block  "><br></br> </span>
                        <span className="block inline xl:text-6xl ">Just one simple price for everything, </span>
                        <span className="block  text-purple xl:text-6xl">forever.</span>
                    </h1>
                    <p className="mt-12 text-xl  px-4 lg:px-32 text-center ">
                        Milo let's you replace multiple vendors with a simple monthly payment. If you're not ready to replace your PIMS, you can start smaller with a suite of our most popular products designed to integrate with your current software.
                    </p>
                </div>
            
            */}

          </main>
        </div>
      </div >
    </div >
  )
}

export default HeroSection;


