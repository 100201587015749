import React from "react";

const PrimaryButton = ({ copy, href = 'href="mailto:hello@getmilo.com"' }) => {
  return (
    <a
      href={href}
      className="w-full flex items-center justify-center px-5 py-2
       border border-transparent text-base font-medium rounded-3xl text-white bg-purple hover:bg-purple-darker 
       md:py-2 md:text-lg md:px-4"
    >
      {copy}
    </a>
  );
}

export default PrimaryButton;
