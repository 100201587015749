import React from "react"
import bgImg from '../../../assets/images/about.jpeg';
import Nav from "../../../components/nav";
/* This example requires Tailwind CSS v2.0+ */
export default function Example() {
    return (
        <div classname="bg-white">

            <div className="relative  pt-6">

                <div className="absolute inset-0">
                    <img
                        className="w-full h-full object-cover"
                        src={bgImg}
                        alt=""
                    />
                    <div className="absolute inset-0 bg-purple-700 mix-blend-multiply" aria-hidden="true" />
                </div>
                <Nav />
                <div className="relative max-w-7xl mx-auto pt-16 pb-20 px-4  sm:px-6 lg:px-8">
                    <h1 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl font-extrabold tracking-tight text-white">
                        <span className=" inline ">Built by a </span>
                        <span className=" inline line-through text-purple-300">former</span>
                        <span className=" inline "> veterinary team</span>
                        <span className=" inline  "> inside</span>
                        <span className=" inline  "> an animal hospital.</span>
                        <span className=" block  "> </span>
                    </h1>
                    <p className="mt-6 text-xl text-purple-100 max-w-3xl">
                        Believe it or not, Milo wasn't always a software company. We began as a veterinary hospital. So when we say that Milo was built by a veterinary team, we mean it. And when we say inside of a hospital, we mean that some of the code was written in an empty exam room.
                    </p> <p className="mt-6 text-xl text-purple-100 max-w-3xl">
                        After three years of running our own hospitals, we walked away with two things: a deep understanding of what it takes to run an animal hospital and an even deeper respect for the people who work inside them.</p>
                    <p className="mt-6 text-xl text-purple-100 max-w-3xl">
                        Those two things are the foundation upon which we built our software. </p>
                    <p className="mt-6 text-xl text-purple-100 max-w-3xl">
                        Today, we no longer operate those hospitals. We focus exclusively on providing our software to other hospitals. A lot changed when we transitioned our business but our mission remains the same: to make teams that work in the animal care industry happy.
                    </p>


                </div>

            </div>
        </div >
    )
}
