import React from 'react';
import HeroSection from './sections/hero';

// import HomepageCollar from '../../assets/images/homepage_collar.png';
// import PrimaryButton from '../../components/primary-button';
// import SecondaryButton from '../../components/secondary-button';
import FooterMain from '../../components/footer';
import ContactUs from '../../components/contactus';
import Klaviyo from '../../components/klaviyo';
import ReactGA from 'react-ga';

const Contact = () => {
    return (
        <div className="bg-white overflow-hidden">
            <HeroSection />


            {/* <Klaviyo /> */}
            <ContactUs />

            <FooterMain />
        </div>
    )
}

export default Contact;
