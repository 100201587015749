import React from 'react';

import HomepageBillingScreenshot from '../../../assets/images/homepage_billing_screenshot.png';
import CtaLink from '../../../components/cta-link';

const LeftRightScreenshotVariation = () => {
  return (
    <div className="relative py-16 mt-16 bg-beige">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div className="mt-6">
                <h2 className="text-6xl font-extrabold tracking-tight text-gray-900 uppercase font-condensed">
                  We are all product owners
                </h2>
                <p className="mt-4 text-lg text-black">
                  At Milo, we wouldn’t make a product that we wouldn’t want to use ourselves.
                </p>
                <p className="mt-4 text-lg text-black">
                  That’s why we are all responsible for making our product better. Everyone on our team has spent time inside an animal hospital, provided customer support, and championed features to improve our product.
                </p>
                <p className="mt-4 text-lg text-black">
                  Some of our most successful features came from outside of the core product team. Other features have come through conversations with our customers.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none z-10 relative xl:-top-32 xl:left-32 md:left-0"
                src={HomepageBillingScreenshot}
                alt="A screenshot showing billing items for a current patient visit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftRightScreenshotVariation;
