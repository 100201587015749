import React, { useState } from 'react'
import ReactGA, { ga } from 'react-ga';


export default function Example() {
    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [website, setWebsite] = useState('')
    const [message, setMessage] = useState('')
    const [isSent, setIsSent] = useState(false)
    const thankYouMessage = <p>Thank you for your input!</p>


    const zapier = e => {
        e.preventDefault()
        fetch(`https://hooks.zapier.com/hooks/catch/8091776/bt57oe6/`, {
            method: 'POST',
            body: JSON.stringify({ fullname, email, phone, message, website }),
        }).then(() => setIsSent(true)
        )
            .catch(() => alert("There was an error, please try again or email us at info@getmilo.com"))
        ReactGA.event({ category: 'LEAD', action: 'Contact', label: 'Web Form' })
        alert("We got your form submission! Someone will reach out to you shortly.")
    }
    return (
        <div className="relative bg-transparent">
            <div className="absolute inset-0">
                <div className="absolute inset-y-0 left-0 w-1/2 bg-transparent" />
            </div>
            <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                <div className="bg-transparent py-8 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-4 xl:pr-12">
                    <div className="max-w-lg mx-auto">
                        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Get in touch</h2>
                        <p className="mt-3 text-lg leading-6 text-gray-800">
                            No matter where you are in the process of switching or considering a switch of your software, we're here to help.

                        </p>
                        <p className="mt-3 text-lg leading-6 text-gray-800">
                            We take a long-term approach to building new relationships. If you're not ready to make a switch, we won't spam you with emails or nag you with phone calls.

                        </p>
                        {/* <dl className="mt-8 text-base text-gray-500">
                            <div>
                                <dt className="sr-only">Postal address</dt>
                                <dd>
                                    <p>New York, NY</p>
                                    <p>Denver, CO</p>
                                </dd>
                            </div>
                            <div className="mt-6">
                                <dt className="sr-only">Phone number</dt>
                                <dd className="flex">
                                    <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                    <span className="ml-3"></span>
                                </dd>
                            </div>
                            <div className="mt-3">
                                <dt className="sr-only">Email</dt>
                                <dd className="flex">
                                    <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                    <span className="ml-3">info@getmilo.com</span>
                                </dd>
                            </div>
                        </dl> */}

                    </div>
                </div>
                <div className="bg-transparent py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                    <div className="max-w-lg mx-auto lg:max-w-none">
                        <form
                            onSubmit={zapier}
                            // action="https://hooks.zapier.com/hooks/catch/8091776/bt57oe6/" method="POST"
                            className="grid grid-cols-1 gap-y-6">
                            <div>
                                <label htmlFor="fullname" className="sr-only">
                                    Your name
                                </label>
                                <input
                                    type="text"
                                    name="fullname"
                                    id="fullname"
                                    autoComplete="name"
                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
                                    placeholder="Full name"
                                    value={fullname}
                                    onChange={e => setFullname(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="sr-only">
                                    Email
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
                                    placeholder="Email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="phone" className="sr-only">
                                    Phone
                                </label>
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    autoComplete="tel"
                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
                                    placeholder="Phone"
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="website" className="sr-only">
                                    Website of your practice
                                </label>
                                <input
                                    type="text"
                                    name="website"
                                    id="website"
                                    autoComplete="url"
                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
                                    placeholder="www.myvetpractice.com"
                                    value={website}
                                    onChange={e => setWebsite(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="message" className="sr-only">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    rows={4}
                                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500 border border-gray-300 rounded-md"
                                    placeholder="Message"
                                    defaultValue={''}
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                />
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="

                                    inline-flex justify-center py-2 px-6 border border-transparent shadow-xl text-base font-medium rounded-3xl text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
