import React from 'react';
import PrimaryButton from '../../../components/primary-button';
import SecondaryButton from '../../../components/secondary-button';
import Nav from '../../../components/nav/black';




const HeroSection = () => {
  return (
    <div className="">
      <div className="relative bg-white pb-8  shadow-xl ">
        {/* <div className="
          absolute left-[-50%] w-[200%] right-[0] bg-[#Fecc15] overflow-hidden
          top-[24%] sm:top-[12%] md:top-[15%] lg:top-[13%] xl:top-[16%]
          h-[19%] sm:h-[18%] md:h-[25%] "
          style={{
            // position: 'absolute',
            // top: '20%',
            // left: '-50%',
            // right: 0,
            // height: '25%',
            // width: '200%',
            // backgroundColor: '#Fecc15',
            transform: 'rotate(-9deg)'
          }} /> */}
        <div className="
          absolute left-[-50%] w-[200%] right-[0] bg-gray-100 overflow-hidden
          top-36
          h-80 "
          style={{
            // position: 'absolute',
            // top: '20%',
            // left: '-50%',
            // right: 0,
            // height: '25%',
            // width: '200%',
            // backgroundColor: '#Fecc15',
            transform: 'rotate(-9deg)'
          }} />



        <div className="relative pt-6 sm:pb-10  ">
          <Nav />

          <main className="lg:relative">


            {/* Overlapping background */}
            <div aria-hidden="true" className="hidden absolute  w-full h-6 bottom-0" />

            <div className="flex flex-col max-w-7xl mx-auto pt-16 pb-20  text-left items-start px-4 sm:px-8 xl:pr-16  ">
              <h1 className="flex flex-col font-extrabold tracking-tight text-black/80 text-4xl sm:text-5xl lg:text-6xl xl:text-7xl max-w-7xl ">
                <div>Milo Subscription Terms of Service</div>

              </h1>

              <div className="mt-4 sm:mt-8 text-lg md:text-lg  sm:pr-32 lg:pr-96 text-left ">
                <p className="py-4">

                  THESE TERMS OF SERVICE (THESE “TERMS”) GOVERN SUBSCRIPTION TO AND USE OF MILO'S SERVICES.
                  IF YOU REGISTER FOR A FREE TRIAL OF MILO'S SERVICES, THE APPLICABLE PROVISIONS OF THESE TERMS WILL ALSO GOVERN THAT FREE TRIAL.
                  {/* BY ACCESSING OR USING MILO'S WEBSITES AND SERVICES, YOU ACCEPT OR AGREE TO THESE TERMS. */}
                  IF YOU ARE ENTERING INTO OR ACCEPTING THESE TERMS ON BEHALF OF A LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY AND CAPACITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS, IN WHICH CASE, THE TERM “SUBSCRIBER” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES.
                  IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE TO BE BOUND BY ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS OR USE MILO'S SERVICES.
                  THESE TERMS DO NOT APPLY TO SUBSCRIBERS WHO BEGAN USE OF MILO'S PLATFORM BEFORE THE DATE THESE TERMS WERE PUBLISHED ON OCTOBER 1ST 2021.

                  <br />  LAST UPDATED: OCTOBER 1, 2021
                  <br />  EFFECTIVE DATE: OCTOBER 1, 2021
                </p>

                <h2 className="text-3xl pt-4">1.	DEFINITIONS</h2>
                <p className="py-4">
                  Capitalized terms used but not defined after this Section have the meanings provided where first used.
                  <br /> 1.1."Subscriber" means entity using Milo's subscription and/or software
                  <br /> 1.2.“Authorized User” means any employees, agents or representatives of the SUBSCRIBER, subject to the relevant Order.
                  <br /> 1.3.“Confidential Information” means non-public or proprietary information in any form disclosed by or on behalf of either Party that (a) is marked or identified as “confidential” or with a similar designation, or (b) by its nature or the circumstances of its disclosure ought reasonably to be treated as confidential.
                  <br /> 1.4.“Intellectual Property” means all intellectual property, including, but not limited to, copyrights, patents, trademarks, trade names, trade secrets, and all registrations, applications, renewals, extensions, divisions or reissues thereof.
                  <br /> 1.5.“Order” means Milo’s standard order form or any similar document referencing this Agreement and executed by both Parties that SUBSCRIBER uses to subscribe to the Services.
                  <br /> 1.6.“Patient” means a pet and its owner(s) or other persons responsible for obtaining and monitoring veterinary care for pets.
                  <br /> 1.7.“Platform” means Milo’s proprietary technology, source code, APIs and workflow for providing technology-enabled pet care and telemedicine.
                  <br /> 1.8.“Product Data” means any information or data compiled from or generated by the use of the Platform and Services, including but not limited to application usage statistics, data on user engagement and other data produced or created as a result of the use of the Platform and Services.
                  <br /> 1.9.“Services” means access to and use of the Platform, inclusive of any functions necessary to enable such access.
                  <br /> 1.10.“Term” means the term of this Agreement and the subscription to the Services, as provided in Section ‎10.
                  <br /> 1.11.“SUBSCRIBER Data” means any information, data, text, pictures, other materials or content submitted or uploaded to the Platform by SUBSCRIBER, including but not limited to Patient medical or billing data and statistical data that can be compiled from any information submitted or uploaded to the Platform by SUBSCRIBER.
                  <br /> 1.12.“Update” means a new version, revision, or other modification of the Platform or any of its elements.
                </p>
                <h2 className="text-3xl pt-4">2. INTELLECTUAL PROPERTY</h2>
                <p className="py-4">
                  <br />2.1.	Milo.
                  <br /> a)	Subject to the limited licenses granted herein, Milo retains all right, title, and interest in and to (i) the Services and Platform (including all UX features, visual expressions, screen and report formats and other design features of the Platform, and all ideas, methods, algorithms, formulae and concepts used in developing and/or incorporated into the Services), and (ii) any modifications or enhancements to (i).
                  <br />  b)	Subject to this Agreement, Milo grants SUBSCRIBER and its Authorized Users a worldwide, non-exclusive, non-transferable (except as set forth in Section 12.5) license to, during the Term, access and use the Platform and Services pursuant to an applicable Order.
                  <br />  c)	All feedback, suggestions, improvements, and recommendations regarding the Platform and Services (“Feedback”) will be owned by Milo, and SUBSCRIBER hereby assigns all right, title and interest in and to such Feedback provided to Milo by SUBSCRIBER or any Authorized Users in connection with this Agreement.
                  <br /> d)	Milo retains all right, title, and interest in and to the Product Data.  For the avoidance of doubt, SUBSCRIBER disclaims all ownership, if any, in and to the Product Data, and to the extent it has any rights in or to the Product Data, it consents to Milo using the Product Data for its business purposes.
                  <br /> 2.2.	SUBSCRIBER.
                  <br />  a)	SUBSCRIBER retains all right, title, and interest in and to the SUBSCRIBER Data created or migrated to the platform during the term.  SUBSCRIBER grants Milo a fully-paid, royalty-free, worldwide, non-exclusive and sublicensable license to (a) during the Term, use, reproduce, modify (as required for optimization with respect to the Platform), and display any SUBSCRIBER Data to provide and improve the services.

                </p>

                <h2 className="text-3xl pt-4">3.	OBLIGATIONS AND RIGHTS OF THE PARTIES</h2>
                <p className="py-4">
                  <br /> 3.1.	As applicable to provide the Services, Milo will host, operate, maintain, and support the Platform as set forth in this Agreement, and will make Updates available to SUBSCRIBER in accordance with its standard business practices. Milo will grant SUBSCRIBER access to the Platform as set forth herein, including providing an account and a password to SUBSCRIBER and/or it Authorized Users.
                  <br /> 3.2.	Milo shall provide, as needed and for a fee specified in the applicable Order, services to assist SUBSCRIBER in setting up and using the Services, including, among other things, installing, integrating, or testing the Platform on any SUBSCRIBER systems and training Authorized Users on how to use the Platform.  The fee(s), if any, for such services will be specified in the applicable Order and, at Milo’s sole discretion, may or may not be included in the license fee for the Licensed Name.
                  <br />  3.3.	SUBSCRIBER will (a) use the Platform and Services in accordance with this Agreement and all applicable laws, (b) prevent unauthorized access to or use of the Platform and notify Milo promptly of any such unauthorized access or use, (c) cooperate with Milo as necessary for Milo to perform the Services, and (d) be responsible for its Authorized Users’ acts and omissions.
                  <br />3.4.	SUBSCRIBER, including its Authorized Users, is solely responsible for maintaining the security of its password and is solely responsible for any use of or action taken under its password or the password of any of its Authorized Users.
                  <br />3.5.	The Services may use third party software programs, the terms of which are governed by the terms and conditions of use of such third-party software.  SUBSCRIBER agrees to that it will agree to and/or comply the terms and conditions applicable to the use of such third-party software programs.
                  <br />3.6.	Milo software is provided as is and features are subject to change. Any addition or removal of features will be shared with SUBSCRIBERS in a timely manor. Unless specified in a service order, Milo is under no obligation to provide features that are discussed before, during or after onboarding that are not yet available. SUBSCRIBER maintains their right to terminate service as per section 10 of this agreement.
                </p>

                <h2 className="text-3xl pt-4">4.	PAYMENTS</h2>
                <p className="py-4">
                  <br />4.1.	Monthly Fees and Expenses. In consideration for the subscription(s) to the Services during the Term and any Renewal Terms, Milo will invoice  and charge SUBSCRIBER on a monthly basis for and SUBSCRIBER will pay Milo the fees and expenses set forth in any Order(s).
                  <br />4.2.	Taxes. The Charges are exclusive of, and SUBSCRIBER will be responsible for payment of, all taxes, fees, duties, and other governmental charges (aside from taxes on Milo’s income related to this Agreement) (the “Taxes”), and any related penalties and interest, imposed on the Charges or SUBSCRIBER’s receipt of the Services (including if any such taxes are incurred after payment of any Charges).  In the event that SUBSCRIBER believes it is exempt from any Taxes, SUBSCRIBER shall provide proper documentation to Milo to evidence such exemption.
                  <br /> 4.3.	Payment Terms for Credit Card Transactions.
                  <br /> a) Milo will deduct from processed payments any applicable and previously agreed upon fees before depositing funds into the SUBSCRIBER's account.
                  <br />  b) Milo uses Stripe Inc. to process these transactions and SUBSCRIBER agrees to all terms set forth in Stripe's terms of service.
                  <br /> 4.4.	Payment Terms for all other charges.
                  <br /> a)	SUBSCRIBER will provide and maintain a credit card or ACH information to enroll in an auto-payment program for payment of subscriptions, which will be automatically charged on the 1st of each month for the prior calendar month. If the 1st of the month falls on a day of the week in which payment cannot be made, Milo will collect payment on the next available day.
                  <br /> b)	Charges for Milo subscription services will be automatically calculated and charged by Milo based on revenue processed within Milo’s software in the prior calendar month. SUBSCRIBER has 30 days from the date of invoice receipt to review and dispute any automatic payments. Any reductions in invoiced amounts that results in overpayment by SUBSCRIBER will be applied as a credit to SUBSCRIBER’s account and reduce future payments. Any account credits at the end of the term of this Agreement will be provided by Milo to SUBSCRIBER as a check or wire within 30 days of the end of the Agreement.
                  <br /> c)	If Milo discovers an error that results in underpayment of subscription services by SUBSCRIBER, it will have 90 days to provide notice to SUBSCRIBER, who will in turn have 15 days to review such charges before automatic payment is processed.
                  <br />d)	SUBSCRIBER must maintain and replace all recurring payment within 7 days of expiration.
                  <br />e)	For any recurring payments paid fifteen (15) days after SUBSCRIBER’s receipt of the applicable subscription charge, Milo may charge interest at the lower of one percent (1%) per month or the maximum rate allowed by applicable law on all late payments by SUBSCRIBER.
                  <br />f)	All non-subscription invoices shall be paid by SUBSCRIBER within fifteen (15) days after SUBSCRIBER’s receipt of the applicable invoice.  For any invoices paid fifteen (15) days after SUBSCRIBER’s receipt of the applicable invoice, Milo may charge interest at the lower of one percent (1%) per month or the maximum rate allowed by applicable law on all late payments by SUBSCRIBER.
                  <br /> g)	Any alternative payment agreements, discounts or exceptions to the above payment terms are at Milo’s sole discretion.
                  <br /> 4.5.	Suspension. In addition to all other remedies, Milo may suspend SUBSCRIBER’s access to the Services if any fees remain unpaid thirty (30) days after their due date up and until SUBSCRIBER has paid all such fees outstanding under this Agreement, plus any costs incurred by Milo in connection with any failed payments.

                </p>

                <h2 className="text-3xl pt-4"> 5.	WARRANTY.</h2>
                <p className="py-4">
                  THE SERVICES ARE PROVIDED “AS IS” AND MILO EXPRESSLY DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES RELATING TO THE SERVICES, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR PURPOSE, TO THE MAXIMUM EXTENT ALLOWABLE BY APPLICABLE LAW.
                </p>
                <h2 className="text-3xl pt-4"> 6.	INSURANCE. </h2>
                <p className="py-4">
                  SUBSCRIBER represents that, as applicable, it, each SUBSCRIBER facility covered by an Order, and the providers at each such SUBSCRIBER facility have as of the Effective Date, and warrants that, as applicable, each of the foregoing will maintain and keep in full force and effect throughout the Term and any Renewal Terms, the following insurance policies:  (a) General Liability; (b) Malpractice Liability; (c) Cyber Liability.  Upon reasonable request from Milo, SUBSCRIBER shall furnish copies of the certificates of insurance evidencing the coverage prescribed above in addition to copies of such insurance policies as such Party may request.
                </p>
                <h2 className="text-3xl pt-4">  7.	LIMITATION OF LIABILITY </h2>
                <p className="py-4">
                  <br />  7.1.	LIABILITY. NEITHER PARTY WILL BE LIABLE UNDER ANY THEORY OF DAMAGES FOR ANY LOST BUSINESS, LOST PROFITS, LOST SAVINGS, LOST REVENUE, OR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES ARISING OUT OF A BREACH OF THIS AGREEMENT, EVEN IF THE PARTY FROM WHICH SUCH DAMAGES ARE SOUGHT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. MILO’S MAXIMUM LIABILITY FOR DAMAGES ARISING OUT OF A BREACH OF THIS AGREEMENT WILL BE THE FEES PAID BY SUBSCRIBER FOR THE TWELVE (12) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE CLAIM.
                  <br />  7.2.	EXCEPTIONS AND APPLICABILITY. SECTION ‎7.1 WILL NOT APPLY TO A PARTY’S INDEMNIFICATION OBLIGATIONS, BREACH OF A PARTY’S CONFIDENTIALITY OBLIGATIONS, OR MISAPPROPRIATION OF THE OTHER PARTY’S INTELLECTUAL PROPERTY. SECTION 7.1 WILL APPLY WITHOUT REGARD TO WHETHER OTHER PROVISIONS OF THIS AGREEMENT HAVE BEEN BREACHED OR PROVEN INEFFECTIVE.
                </p>
                <h2 className="text-3xl pt-4">   8.	CONFIDENTIALITY
                </h2>
                <p className="py-4">
                  <br /> 8.1.	Obligations. All Confidential Information of a Party (“Disclosing Party”) that the other Party (“Receiving Party”) has access to pursuant to this Agreement will be held in strict confidence, and the Receiving Party will protect such Confidential Information using measures at least as protective as it uses to protect its own Confidential Information, but in no event less than a reasonable degree of care. The Receiving Party will only use the Disclosing Party’s Confidential Information as necessary to implement or perform this Agreement and will limit access to the Disclosing Party’s Confidential Information to those of its employees or representatives whose responsibilities require such use or access and who are bound by confidentiality obligations at least as protective as those in this Section .
                  <br />8.2.	Exceptions. Confidential Information does not include, and the obligations set forth in Section 8.1 will not apply to, any information that: (a) was already known to the Receiving Party at the time of disclosure by the Disclosing Party; (b) was disclosed to the Receiving Party by a third party who had the right to make such disclosure; (c) is, or through no fault of the Receiving Party has become, generally available to the public; or (d) was independently developed by the Receiving Party without access to, or use of, the Disclosing Party’s Confidential Information. In addition, the Receiving Party may disclose the Disclosing Party’s Confidential Information where such disclosure is (i) approved in writing by the Disclosing Party, (ii) necessary for the Receiving Party to enforce its rights under the Agreement; or (iii) required by law, court order, or the equivalent, provided that the Receiving Party promptly notifies the Disclosing Party of such required disclosure in writing and reasonably cooperates with the Disclosing Party, at the Disclosing Party’s request and expense, to contest or limit the scope of such disclosure.
                  <br /> 8.3.	Return of Confidential Information. The Receiving Party will return or destroy all Confidential Information of the Disclosing Party and permanently erase all electronic copies of such Confidential Information promptly upon the written request of the Disclosing Party or upon the expiration or termination of this Agreement.
                </p>
                <h2 className="text-3xl pt-4">             9.	INDEMNIFICATION
                </h2>
                <p className="py-4">
                  <br /> 9.1.	By Milo.
                  <br /> a)	Milo will defend, indemnify and hold SUBSCRIBER, its agents, officers and employees harmless from all losses, damages, costs and expenses awarded by a court or agreed upon in settlement, including reasonable attorneys’ fees and court costs, arising from a third party claim that the Platform infringes such party’s Intellectual Property Rights (“Infringement Claims”), provided that the Infringement Claim does not arise from SUBSCRIBER’s (i) use of the Platform in violation of this Agreement, or (ii) failure to use Updates to the Platform.
                  <br /> b)	If an Infringement Claim is brought or threatened, Milo may, in its sole discretion, (i) secure the right for SUBSCRIBER to continue using the allegedly infringing item, or (ii) replace or modify the allegedly infringing item to make it non-infringing, while maintaining materially similar functionality of the affected Platform elements. If neither (i) nor (ii) is commercially reasonable in Milo’s sole judgment, SUBSCRIBER will, upon notice from Milo, cease use of the allegedly infringing item and receive an equitable adjustment of the related Charges.
                  <br /> 9.2.	By SUBSCRIBER. SUBSCRIBER will defend, indemnify, and hold Milo, its agents, officers and employees harmless from all losses, damages, costs and expenses awarded by a court or agreed upon in settlement, including reasonable attorneys’ fees and court costs, arising from a third party claim arising out of or relating to any acts or omissions by SUBSCRIBER’s Authorized Users or SUBSCRIBER’s breach of this Agreement.
                  <br /> 9.3.	Procedures. The obligations in this Section ‎9 are contingent on the indemnified Party: (a) promptly notifying the indemnifying Party of any indemnifiable claim; (b) granting the indemnifying Party sole control over the defense and/or settlement of the claim (provided that a settlement may not impose costs or liability on the indemnified Party without its consent); and (c) providing reasonable assistance to the indemnifying Party at such Party’s expense.
                  <br /> 9.4.	Sole Remedy. This Section ‎9 sets forth the indemnified Party’s sole remedy, and the indemnifying Party’s entire liability, with respect to any indemnifiable claim.
                </p>
                <h2 className="text-3xl pt-4">              10.	TERM AND TERMINATION
                </h2>
                <p className="py-4">
                  <br />10.1.	This Agreement is valid from the Effective Date until terminated as set forth herein. Unless otherwise set forth in the applicable Order, the Term begins upon first use or login of any AUTHORIZED USER (“Term”) and will renew automatically for additional one (1) month terms thereafter (each renewal, a “Renewal Term”) absent notice of non-renewal by either Party not less than thirty (30) days before the end of the next term (unless otherwise required by applicable law). Notwithstanding any other termination right provided herein, during the initial Term, either Party may terminate this Agreement or any outstanding Order for a material breach of the other Party that remains uncured thirty (30) days after the breaching Party’s receipt of written notice of the breach.  During any Renewal Term, notwithstanding any other termination right provided herein, either Party may terminate this Agreement or any outstanding Order for (a) a material breach of the other Party that remains uncured thirty (30) days after the breaching Party’s receipt of written notice of the breach or (b) any reason upon thirty (30) days’ notice to the other Party.
                  <br />10.2.	Except upon termination of this Agreement by Milo for SUBSCRIBER’s material breach, following the termination of this Agreement or, as applicable, any Order, there may be, at Milo’s discretion, a transition period of no less than one (1) and no more than three (3) months, in order to allow SUBSCRIBER and/or any SUBSCRIBER facility to which Milo is providing Services hereunder to transition to a different technology-enabled pet care and telemedicine platform.  SUBSCRIBER agrees to compensate Milo for any such transition services at an amount agreed upon by the Parties. The minimum method of data export will be an export of comma seperated value files. Any additional methods of export will be at Milo's sole discretion.
                  <br /> 10.3.	Upon termination or expiration of this Agreement or, as applicable, any Order(s), (a) SUBSCRIBER will pay Milo all accrued but unpaid fees up to the date of termination, (b) all licenses granted by Section ‎2 will immediately terminate, (c) SUBSCRIBER will discontinue all access and use of the Platform, and (c) each Party will promptly return and / or delete any Confidential Information of the other Party pursuant to Section ‎8. Unless SUBSCRIBER terminates this Agreement and/or any outstanding Order for Milo’s material breach, termination will not relieve SUBSCRIBER of its obligation to pay all fees for the remainder of the Term then in effect, and such fees will not be refunded if prepaid, or will be immediately due and payable upon termination if not previously received.
                  <br /> 10.4.	Sections ‎2, 3, 5, 7, 8, 9, 10, 11, 12.2 and 12.7 will survive the termination or expiration of this Agreement.

                </p>
                <h2 className="text-3xl pt-4">           11.	WARRANTIES AND REPRESENTATIONS.
                </h2>
                <p className="py-4">
                  <br />11.1.	Each Party represents that it has all requisite right, power, and authority to enter into this Agreement, perform its obligations, and grant the rights, licenses, and authorizations in this Agreement.
                  <br /> 11.2.	Milo represents that it owns and/or has all licenses and other rights required to use the Intellectual Property required for it to provide the Services and warrants that it will maintain such ownership and/or rights during the Term.
                  <br /> 11.3.	SUBSCRIBER represents that it owns and/or has all licenses and other rights required to use the SUBSCRIBER Data as required for Milo to provide the Services and warrants that it will maintain such ownership and/or rights during the Term.
                  <br /> 11.4.	SUBSCRIBER warrants that it and each of its facilities subject to an Order or Orders will comply with all applicable laws, rules, regulations, directives, standards, guides and guidance, including but not limited all such laws, rules, regulations, directives, standards, guides and guidance applicable to the veterinary care industry.

                </p>
                <h2 className="text-3xl pt-4">             12.	OTHER PROVISIONS
                </h2>
                <p className="py-4">
                  <br />12.1.	Attribution. Milo may use SUBSCRIBER’s name and logo in any routine list of Milo clients or customers.
                  <br />12.2.	No Third-Party Beneficiaries. This Agreement is solely for the benefit of the Parties, and no other party may bring an action related to any breach of its terms and provisions.
                  <br /> 12.3.	Notice. All notices, consents and other communications intended to have legal effect regarding this Agreement will be in writing and will be deemed to have been received on the earlier of the date of actual receipt or the first business day after being sent by a reputable overnight delivery service. Operational communications, including changing a Party’s notice address, may be via email.
                  <br /> 12.4.	Assignment. Neither Party may assign this Agreement without the other Party’s prior written consent, except if there is a merger, consolidation or sale of all or substantially all of a Party’s stock or assets.
                  <br />12.5.	Relationship. The relationship between the Parties under this Agreement is that of independent contractors. Nothing herein shall be construed to create between the Parties a relationship of partners or joint venturers or of franshisor/franschisee, and neither Party may, nor will represent to any third party that it can, enter into any binding obligations on behalf of the other Party.
                  <br /> 12.6.	Governing Law and Venue. This Agreement, including its formation, will be construed and enforced according to the laws of the state of New York, without reference to its conflicts of laws provisions that would require a different result. Each Party consents to the exclusive jurisdiction of the state and federal courts located in New York County, New York for the resolution of any claim or action it initiates in relation to this Agreement.
                  <br /> 12.7.	Force Majeure. Neither Party will be liable for a failure to fulfill its obligations due to causes beyond its reasonable control that cannot be mitigated through the exercise of due care.
                  <br />12.8.	Non-Exclusive Services.  SUBSCRIBER acknowledges and agrees that the Services are provided on a non-exclusive basis and that Milo shall be permitted to render the Services to others, including individuals or entities that SUBSCRIBER may consider SUBSCRIBER’s competitors.
                  <br /> 12.9.	Entire Understanding; Precedence. This Agreement, including any Orders and other attachments or exhibits, states the Parties’ entire understanding with respect to its subject matter, and supersedes all prior written or oral communications between the Parties about such subject matter. Any terms in a SUBSCRIBER purchase order or authorization will have no legal effect. If there is a conflict between the Agreement and an Order, the Agreement will control unless the Order specifically references the provision of the Agreement to be superseded.
                  <br />12.10.	Modification and Waiver. No modification of this Agreement or waiver of any breach of this Agreement, will be effective unless in writing and signed by an authorized representative of both Parties. No waiver of any breach of this Agreement or course of dealing between the Parties will be construed as a waiver of any subsequent breach of this Agreement.
                  <br />12.11.	Severability. If any portion of any provision of this Agreement is held to be illegal, iSubscriberlid or unenforceable, in whole or in part, (a) such unenforceable portion of the provision will be deemed severed from this Agreement, (b) the remaining portion of the provision and the other provisions of this Agreement will not be affected or impaired, and (c) this Agreement will be amended in order to effect, to the maximum extent allowable by law, the original intent of such provision.
                  <br /> 12.12.	Counterparts. This Agreement may be executed in one or more counterparts, each of which will be deemed an original and all of which together will constitute one and the same instrument.
                  <br /> 12.13.	Construction. Section headings are for convenience and are not to be used in interpretation. As used herein, the word “including” means “including but not limited to,” and “may” means “has the right, but not the obligation, to.

                </p>



              </div>

            </div>




            {/* <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:pt-32 lg:pb-20 xl:py-32 lg:text-left " >
              <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16 ">
                <h1 className="text-5xl tracking-tight font-semibold text-gray-900 sm:text-6xl  bg-blend-overlay">
                  <span className="block xl:inline opacity-80">One window to rule them all</span>{' '}
                </h1>
                <p className="mt-3 max-w-md mx-auto text-lg text-black sm:text-xl md:mt-5 md:max-w-3xl">
                  Let's be honest, veterinary software is a mess. The average staff members has over four windows open at a time. Not only does this slow them down, it leads to mistakes and frustrates your team and customers. That's why Milo takes an all-in-one approach to simplify your technology.
                </p>
                <p className="mt-3 max-w-md mx-auto text-lg text-black sm:text-xl md:mt-5 md:max-w-3xl">
                  Milo's all-in-one software comes with 25 apps, 50 modules and 1000+ features. You'll never need to pay extra or upgrade to access future features. They are made available in your software automatically every week. Below is a sampling of just a few of the features that come with Milo.
                </p>

                <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                  <div className="left">
                    <CtaLink copy="See a sampling of our features below" mttop="mt-2 text-xl" />
                  </div>


                </div>
              </div>
            </div>
            <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
              <img
                className="absolute top-32 w-full h-1/2 object-cover object-left z-50"
                src={Mess}
                alt=""
              />
            </div> */}
          </main>
        </div>
      </div >
    </div >
  )
}

export default HeroSection;
{/* SUBSCRIPTION AGREEMENT
This Subscription Agreement (the “Agreement”) is entered into as of August [ ], 2021 (the “Effective Date”) between Milo Pets, Inc. (“Milo”) and National Veterinary Associates (“SUBSCRIBER”) The Agreement includes the following terms and conditions, any orders entered into for Milo products or services by SUBSCRIBER and referencing this Agreement, and any other exhibits or schedules attached hereto.  Milo and SUBSCRIBER are each referred to as a “Party” and, collectively, as the “Parties.”
WHEREAS Milo owns a proprietary software platform that is designed to assist veterinary care providers deliver, and pet parents’ access, technology-enabled pet care and telemedicine; and
WHEREAS SUBSCRIBER desires to have access to Milo’s proprietary software platform for its veterinary hospitals and receive services related thereto.
THEREFORE, in consideration of the foregoing and the mutual covenants herein contained, and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the Parties hereby agree as follows:
1.	DEFINITIONS
Capitalized terms used but not defined in this Section have the meanings provided where first used.  
1.1.	“Authorized User” means any employees, agents or representatives of SUBSCRIBER, subject to the relevant Order.
1.2.	“Confidential Information” means non-public or proprietary information in any form disclosed by or on behalf of either Party that (a) is marked or identified as “confidential” or with a similar designation, or (b) by its nature or the circumstances of its disclosure ought reasonably to be treated as confidential.
1.3.	“Intellectual Property” means all intellectual property, including, but not limited to, copyrights, patents, trademarks, trade names, trade secrets, and all registrations, applications, renewals, extensions, divisions or reissues thereof. 
1.4.	“Order” means Milo’s standard order form or any similar document referencing this Agreement and executed by both Parties that SUBSCRIBER uses to subscribe to the Services.
1.5.	“Patient” means a pet and its owner(s) or other persons responsible for obtaining and monitoring veterinary care for pets.
1.6.	“Platform” means Milo’s proprietary technology, source code, APIs and workflow for providing technology-enabled pet care and telemedicine. 
1.7.	“Product Data” means any information or data compiled from or generated by the use of the Platform and Services, including but not limited to application usage statistics, data on user engagement and other data produced or created as a result of the use of the Platform and Services.
1.8.	“Services” means access to and use of the Platform, inclusive of any functions necessary to enable such access.
1.9.	“Term” means the term of this Agreement and the subscription to the Services, as provided in Section ‎10. 
1.10.	“SUBSCRIBER Data” means any information, data, text, pictures, other materials or content submitted or uploaded to the Platform by SUBSCRIBER, including but not limited to Patient medical or billing data and statistical data that can be compiled from any information submitted or uploaded to the Platform by SUBSCRIBER.
1.11.	“Update” means a new version, revision, or other modification of the Platform or any of its elements.  */}