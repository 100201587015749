
import { BeakerIcon, CashIcon, ChatIcon, CheckCircleIcon, ChevronDoubleDownIcon, CloudIcon, CogIcon, CreditCardIcon, CurrencyDollarIcon, DocumentSearchIcon, ExternalLinkIcon, LightningBoltIcon, PencilAltIcon, PhoneIcon, PhoneIncomingIcon, PhoneOutgoingIcon, SearchIcon, ShoppingBagIcon, TrendingUpIcon, UserGroupIcon, WifiIcon } from '@heroicons/react/outline';
// import CalendarPreview from '../../../assets/images/features/calendar-w-preview.png';
import Family from '../../../assets/images/features/family.png';
import TextingConversation from '../../../assets/images/features/texting-conversation.png';
import VoipSmall from '../../../assets/images/features/small-voip.png';
import Membership from '../../../assets/images/features/memberships.png';
import ShippingLabel from '../../../assets/images/features/shipping-label.png';
import LabResults from '../../../assets/images/features/lab-results.png';
import SearchBox from '../../../assets/images/features/search_720.gif';
import Payments from '../../../assets/images/features/cards-on-file.png';
import Automation from '../../../assets/images/features/automations.png';

export const sections = [
  {
    id: 1,
    name: 'PIMS',
    title: 'Start by saying goodbye to your PIMS',
    leftimg: false,
    description: 'Milo begins by allowing you to replace your PIMS with a modern and beautifully designed cloud based solution.',
    img: Family,
    imgwidth: 0,
    features: [
      {
        id: 1,
        name: 'Family-based architecture',
        description: 'Milo allows multiple humans per family: dog walkers, friends and partners. When you maintain a richer set of data about people you interact with: their pronoun, multiple phone numbers, cards on file... your staff can provide an incredible experience.',
        longdescription: 'Milo is the first animal hospital software solution to allow multiple humans per family: dog walkers, friends and partners. Milo helps you track a richer set of information about each human you interact with: their pronoun, multiple phone numbers, cards on file and their relationship to each animal in the family. All of this makes it easier for your staff to communicate with customers the way they want.  ',
        icon: UserGroupIcon,
      },
      {
        id: 2,
        name: 'Say goodbye to double bookings',
        description: 'Milo screens update in real-time without you needing to refresh the page. As soon as someone starts booking an appointment, a hold instantly appears on every other screen in the hospital.',
        icon: CloudIcon,
      },
      {
        id: 3,
        name: 'Software that\'s built to scale',
        description: 'Milo works for busy hospitals (multiple doctors and exam rooms) as well as hospital groups that have multiple locations. ',
        longdescription: 'We designed Milo for hospitals with multiple doctors as well as hospital groups with multiple locations and/or regions. We de-duplicate your clients who might visit more than one location and allow your hospitals to help each other out if you want. You can setup your products to have different prices in different regions. ',
        icon: TrendingUpIcon,
      },
      // {
      //   id: 4,
      //   name: 'Collaborative',
      //   description: 'Most importantly, Milo is designed with collaboration at it\'s core. Small touches throughout the software ensure that your staff can reduce mistakes, avoid stepping on each other\'s toes and eliminate unnecessary inter-office phone calls and sticky notes.',
      //   icon: ChatIcon,
      // },
    ],
  },

  {
    id: 2,
    name: 'Texting',
    title: 'Texting-centric communication that everyone loves',
    leftimg: true,
    description: 'At first, our own staff was hesitant. Ask them today and there\'s no looking back. 99.3% of clients text with us and phone call volume has dropped over 50% inside the hospital. It\'s more efficient for staff and more convenient for our clients than downloading an app.',
    img: TextingConversation,
    imgwidth: 0,
    features: [
      {
        id: 1,
        name: 'Truly integrated, beautifully designed',
        description: 'Use Milo texting from any screen: while viewing lab results or booking an appointment',
        longdescription: 'Milo\'s texting isn\'t in a separate window; it\'s directly in your day to day workflows. Other practice management solutions provide the bare minimum: send and receive text messages with phone numbers in your system.',
        icon: ChevronDoubleDownIcon,
      },
      {
        id: 2,
        name: 'One-click sync to medical records',
        description: 'It only takes one click to add client conversations to medical records. If the conversation is still active, it will automatically add new messages into the record.',
        longdescription: 'It takes one click to take any text thread and copy it into the medical records. And if the conversation is still happening, it will automatically sync new updates until the conversation ends. The time that doctors and staff spend writing call back notes? Take it, and cut it in half.',
        icon: ExternalLinkIcon,
      },
      {
        id: 3,
        name: 'Automation done right',
        description: 'Everyone knows when they\'re getting an automated text: "Type STOP to turn off messages." Milo\'s proprietary approach to texting increases response rates and feels personal to everyone involved. It\'s why 99.3% of clients respond to our texts.',
        longdescription: 'The reason we have nearly a 100% response rate in our texting is our careful approach to personalizing our messaging. By adding just a couple seconds to each automated message sent, we save you hours in future work. At the same time, over 50% of text messages sent are automatically created by Milo, saving your staff time.',
        icon: CogIcon,
      },
      {
        id: 4,
        name: 'Customizable',
        description: 'Our texting comes with automated reminders and suggested quick replies. You can create your own as well with or without our help.',
        longdescription: 'Without any help from the Milo team, you can create your own automated messages, reminders and saved replies that you use frequently. Each individual employee can bookmark their favorites so they are readily accessible.',
        icon: PencilAltIcon,
      },
    ],
  },
  {
    id: 3,
    name: 'Search',
    title: 'The best search (period)',
    leftimg: false,
    description: 'Our search bar is always present and ready with lightening fast precision that updates as you type. Why? It\'s the number one way your staff moves around the PIMS. Making your search fast makes your staff fast.',
    img: SearchBox,
    imgwidth: 0,
    features: [
      {
        id: 1,
        name: 'Search partial word matches',
        description: 'Can\'t remember how to spell a name? Not to worry, Milo lets you search a few letters of each word.  ',
        icon: SearchIcon,
      },

      {
        id: 2,
        name: 'Search without declaring what you\'re searching for',
        description: 'What if you could just start typing anything in a box: a phone number, a patient name, a client last name... Well you can. Milo doesn\'t require you to put the first name in one box and the last name in another. It saves time and delivers better results.',
        longdescription: 'Other PIMS give you multiple text-boxes for each data field you might want to search by. Ours is much smarter than that and lets you search by three digits of a phone number and four letters of a patient\'s name, just by putting a space between those two search terms. It\'s like we can read your mind (we can\'t... and anyways, if we could, we\'d want to read the minds of our pets first anyways).',
        icon: DocumentSearchIcon,
      },
      {
        id: 3,
        name: 'Search that\'s always ready and faster than a Greyhound',
        description: 'Milo instantly provides search results as you type. No loading icons or submit button. Your team will save countless seconds.',
        longdescription: 'No matter where you are in Milo, the top search bar is always ready. We using something called indexing to allow us to show near instant results and have those results change in real-time as you type. Eliminating a submit button is just one less click, but we all know that those clicks add up. Drops in the ocean, right? Better yet, pieces of kibble in a bowl.',
        icon: LightningBoltIcon,
      },
      {
        id: 4,
        name: 'Search medical records by diagnosis and symptom',
        description: 'Allow your doctors to search past SOAPs by similar symptoms or diagnoses. ',
        longdescription: 'One of the advanced ways you can search is to find past patients with similar symptoms or diagnoses. Your newer doctors can look up the work of others to help get guidance on assessments and plans that have been used in the past.',
        icon: ChatIcon,
      },
    ],
  },

  {
    id: 4,
    name: 'Payments',
    title: 'Securely store credit cards, pay invoices and issue refunds all without leaving Milo',
    leftimg: true,
    description: 'Our integrated payments, powered by Stripe, removes the need for staff to tie out invoices at the end of the day with your credit card terminal. Over 99% of customers who have been to our hospitals leave a card on file, making future visits faster and safer during COVID.',
    img: Payments,
    imgwidth: 0,
    features: [
      {
        id: 1,
        name: 'Wireless credit card readers',
        description: 'Collect payments anywhere with our wireless and battery powered chip readers, built directly into our software.',
        longdescription: 'We utilize the latest hardware that includes battery powered, wireless credit card chip readers. This allows your staff to accept payment from anywhere in your hospital. Your staff can access any credit card terminal from any computer either inside or even at another location. All entirely secure.',
        icon: WifiIcon,
      },
      {
        id: 2,
        name: 'Seamless payments & refunds',
        description: 'Our payments integration is as simple as paying or refunding an invoice. No separate screens or websites to navigate to.',
        longdescription: 'Our payments integration is as simple as paying or refunding an invoice. There aren\'t separate screens or websites you need to navigate to. Our refunds wizard makes it easy for staff to issue full or partial (percentage or dollar) refunds. Staff can select specific payments methods and issue multiple refunds against the same invoice if needed.',
        icon: CashIcon,
      },
      {
        id: 3,
        name: 'Safely store cards on file',
        description: 'Our payments securely stores cards utilizing tokens meaning you and your staff can\'t see the numbers. They are securely stored in a PCI compliant 3rd-party data center.  ',
        icon: CogIcon,
      },
      {
        id: 4,
        name: 'Charge recurring subscriptions',
        description: 'Charge clients on a recurring basis. Our own membership program has virtually no churn. Members at our hospital spent 3.5x more than non-members.',
        longdescription: 'Our membership model allows you to charge clients on a recurring basis. The results of our own membership program has virtually no churn and sees clients spend significantly more than the average customer.',
        icon: CheckCircleIcon,
      },
    ],
  },
  {
    id: 5,
    name: 'VOIP',
    title: 'A phone integration that\'s not magic, but feels like it',
    leftimg: false,
    description: 'When your staff picks up the phone, our software immediately tells you who it is, any upcoming appointments they have and gives the staff access to take notes as they move around the software.',
    img: VoipSmall,
    imgwidth: 'w-[500px]',
    features: [
      {
        id: 1,
        name: 'Callbacks made easy',
        description: 'Milo\'s software knows where your team is sitting. Anytime they take a call, we know who took it. They can quickly categorize the call and leave notes without ever leaving the screen or having to log back in, no matter how long or short the call was. ',
        icon: PhoneIcon,
      },
      {
        id: 2,
        name: 'Make calls with the click of a button',
        description: 'If you are on a particular appointment, patient or customer page, just hit the green call button and the phone next to you will start ringing. As soon as you pick it up, it will begin dialing the client for you.',
        icon: PhoneOutgoingIcon,
      },
      {
        id: 3,
        name: 'Keep your number',
        description: 'We can integrate with your current phone company and setup your texting number to match the phone number of the hospital making it easy for your customers to get in touch.',
        icon: PhoneIncomingIcon,
      },
    ],
  },
  {
    id: 6,
    name: 'Memberships',
    title: 'Memberships that clients and staff will love',
    leftimg: true,
    description: 'Our team has years of experience in various direct-to-consumer businesses. We used experiences from those industries to craft a customer-centric offering that isn\'t a burden for your staff to administer. ',
    img: Membership,
    imgwidth: 0,
    features: [
      {
        id: 1,
        name: 'Integrated and automated',
        description: 'Milo memberships are built to be easy for your clients and staff. All you need to do is add a card on file and pick a plan for the patient. Everything else is taken care of: monthly charges, upgrades based on age, discounts automatically applied to invoices and missed payments tracked and corrected.',
        icon: CreditCardIcon,
      },
      {
        id: 2,
        name: 'Flexible and configurable',
        description: 'You can design as many membership programs as you want and configure them to fit your practice. You can offer discounts, free items and more.',
        icon: CogIcon,
      },
      {
        id: 3,
        name: 'Drive loyalty and increased sales',
        description: 'At our hospitals where our membership program has been live for over two years, members spend 3.5x more per year than those without a membership. ',
        icon: CurrencyDollarIcon,
      },
    ],
  },

  {
    id: 7,
    name: 'onlinepharmacy',
    title: 'Win back your lost pharmacy revenue',
    leftimg: false,
    description: 'Milo has been built to allow you to win back business you\'ve lost to online pharmacies.',
    img: ShippingLabel,
    imgwidth: 'w-[500px]',
    features: [
      {
        id: 1,
        name: 'Saved credit cards',
        description: 'The first step in competing with websites like Chewy.com is to make the experience for your clients seamless. If you are out of stock of a prescription and will have it at a later date, or need to provide a refill, that\'s much harder to do if you don\'t have a simple way to charge them. We\'ve made it simple; request a refill and we’ll automatically create the invoice, a few clicks to charge their card and you\’re all set.',
        icon: CreditCardIcon,
      },
      {
        id: 2,
        name: 'Print, ship and track prescription orders',
        description: 'There\'s no need to leave Milo to refill a patients prescription. Just open their subscription and you\'re given the option to create an order and print a label.',
        icon: ShoppingBagIcon,
      },
      {
        id: 3,
        name: 'Intelligent tracking of third party prescriptions',
        description: 'Most hospitals can\'t answer the question of "how much revenue do we lose to third parties?" With Milo, all of your prescriptions, whether you sell them in the office, ship them to a home or approve a third party order, are tracked in detail. This allows you to use Milo automate to send a timely reminder to clients in need of refills, even if you didn\'t fill the first script.',
        icon: LightningBoltIcon,
      },
    ],
  },
  {
    id: 8,
    name: 'Lab and Diagnostic Integrations',
    title: 'We took months perfecting our integrations and then made them even better',
    leftimg: true,
    description: 'One of the advantages of working with Milo is that we expect you to need an integration that we don\'t have. That\'s why a portion of our engineering team is always reserved to build integrations. We can build integrations in four weeks or less. ',
    img: LabResults,
    imgwidth: 'w-[900px]',
    features: [
      {
        id: 1,
        name: 'Integrations you expect, and some you don\'t',
        description: 'Milo integrates with your on-site lab equipment as well as reference labs. Our integrations with Antech, IDEXX and Sound have each been carefully tested. And where possible, such as with our Antech integration, we use the latest versions of integrations possible allowing you a better and more seamless experience of ordering and reviewing lab results.',
        icon: BeakerIcon,
      },
      {
        id: 2,
        name: 'Automated texting and call-back work flows',
        description: 'For every lab result that comes in, you are given four additional options: you can text the owner right away, assign a text message for someone else to send or do the same things via a call or callback. As soon as you create those tasks, the lab result is automatically acknowledge and you are brought back into the lab results tab. The task you create and assign will have a copy of the lab results and order information so you don\'t have to open a new tab or navigate away to complete the task at hand.',
        icon: LightningBoltIcon,
      },
      {
        id: 3,
        name: 'Custom integrations',
        description: 'If you don\'t see a partner listed that you use, we\'re either already working on it, or can build that integration for you. An entire portion of our software engineering team is dedicated to building and maintaining our integrations.',
        icon: CogIcon,
      },
    ],
  },

  {
    id: 9,
    name: 'Milo automations',
    title: 'Take your practice to the next level by configuring automated messages and tasks for your staff and customers',
    leftimg: false,
    description: 'Milo automations take "reminders" to a whole new level. Our automation engine can remind clients about care they need for all of the pets in their family at the same time. ',
    img: Automation,
    imgwidth: 'w-[500px]',
    features: [
      {
        id: 1,
        name: 'Fully configurable',
        description: 'Write your own copy using variables, schedule multiple actions per automation such as text messages and internal tasks and filter automations to apply to first time clients or specific appointment types. ',
        icon: LightningBoltIcon,
      },
      {
        id: 2,
        name: 'Fully integrated',
        description: 'Our automations are driven by events that happen inside of Milo. That means that if a staff member confirms an appointment on the calendar, we will instantly complete the reminder that was generated yesterday, even if your staff forgets. We keep your software and data clean so you don\'t have to decide whether you want to stare at data from six ago months ago or click 100 checkboxes in a row.',
        icon: CogIcon,
      },
      {
        id: 3,
        name: 'Reach customers how they want to be reached',
        description: 'Milo gives you the option of contacting customers via a variety of methods to ensure you get high rates of engagement when reminding them about annual vaccines or recommended procedures.',
        icon: PhoneIcon,
      },
    ],
  },

  //new sections go above here
]
export default sections
