import React from 'react';

import CtaLink from '../../cta-link';

const LeftRightScreenshot = ({ title, copy = [], screenshot, screenshotAlt = "", ctaCopy, ctaHref, quote, quoteAuthor }) => {
  return (
    <div className="relative pt-16 pb-16 lg:pb-2 overflow-hidden xl:pb-0 bg-beige">
      <div className="relative xl:h-[950px]">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:pt-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div className="mt-6 pb-8">
                <h2 className="text-5xl lg:text-6xl font-extrabold tracking-tight text-gray-900 uppercase font-condensed">
                  {title}
                </h2>
                {
                  copy.map((c) => (
                    <p className="mt-4 text-lg text-black">
                      {c}
                    </p>
                  ))
                }
                <CtaLink copy={ctaCopy} href={ctaHref} mttop="mt-2 text-xl" />
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="px-4 -mr-48 sm:px-6 md: h-1/2 lg:px-4 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-4/5 px-4  md:px-8  md:max-w-4xl lg:h-auto  lg:w-[800px]  lg:right-0 xl:-mr-48 lg:pt-24 lg:pb-8 xl:h-3/5 xl:w-auto  lg:max-w-none"
                src={screenshot}
                alt={screenshotAlt}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftRightScreenshot;
