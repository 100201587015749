import React, { useRef, useEffect } from 'react';

import HomepageScreenshotGallery from '../../../assets/images/carousel1x.png';

const GALLERY_SCREENSHOT_STYLES = {
  display: 'inline-block',
  minHeight: '400px',
  maxHeight: '600px',
  height: '60vh'
};

const ScreenshotGallery = () => {
  const refLeft = useRef();
  const refMiddle = useRef();
  const refRight = useRef();

  useEffect(() => {
    window.onload = () => {
      refLeft.current.classList.add("float-right-starting-left");
      refMiddle.current.classList.add("float-right-starting-middle");
      refRight.current.classList.add("float-right-starting-right");
    }
  });

  return (
    <div className="relative bg-white py-8 overflow-hidden z-10">
      <div style={{
        position: 'absolute',
        top: '25%',
        left: '-50%',
        right: 0,
        height: '50%',
        width: '200%',
        backgroundColor: '#F2A9B5',
        transform: 'rotate(-9deg)'
      }} />
      <img ref={refLeft} src={HomepageScreenshotGallery} className="object-cover z-10 absolute max-w-none" alt="Screenshots of various Milo software features" style={GALLERY_SCREENSHOT_STYLES} />
      <img ref={refMiddle} src={HomepageScreenshotGallery} className="object-cover z-10 absolute max-w-none" alt="Screenshots of various Milo software features" style={GALLERY_SCREENSHOT_STYLES} />
      <img ref={refRight} src={HomepageScreenshotGallery} className="object-cover z-10 absolute max-w-none" alt="Screenshots of various Milo software features" style={GALLERY_SCREENSHOT_STYLES} />
      <img src={HomepageScreenshotGallery} className="z-10 opacity-0 relative max-w-none" alt="" style={GALLERY_SCREENSHOT_STYLES} />
    </div>
  );
};

export default ScreenshotGallery;
