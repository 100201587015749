import React from 'react';
import PrimaryButton from '../../../components/primary-button';
import SecondaryButton from '../../../components/secondary-button';
import Nav from '../../../components/nav/black2';
import ReactGA from 'react-ga'



const HeroSection = () => {
  return (
    <div className="">
      <div className="relative bg-white ">

        <div className="
          absolute left-[-50%] w-[200%] right-[0] bg-[#Fecc15] overflow-hidden
          top-36
          h-48 sm:h-56 xl:h-72 "
          style={{

            transform: 'rotate(-9deg)'
          }} />



        <div className="relative pt-6 sm:pb-10">
          <Nav />

          <main className="lg:relative">


            {/* Overlapping background */}
            <div aria-hidden="true" className="hidden absolute  w-full h-6 bottom-0" />

            <div className="flex flex-col max-w-7xl mx-auto pt-16 pb-12  text-left items-start px-4 sm:px-8 xl:pr-16  ">
              <h1 className="flex flex-col font-extrabold tracking-tight text-black/80 text-4xl sm:text-5xl lg:text-6xl xl:text-7xl max-w-7xl ">
                <div>25+ built-in apps</div>
                <div>50+ modules</div>
                <div>500+ incredible features</div>
                <div className=""><span className="text-white"> One </span>window open at a time.</div>


              </h1>

              <div className="inline">
                <div className=" inline-block mt-4 sm:mt-8  text-black/80 font-extrabold text-4xl sm:text-5xl lg:text-6xl xl:text-7xl">
                  <div className="inline-block">All-in-
                    <div className="text-orange inline-block">
                      one&nbsp;

                    </div>
                  </div>
                  veterinary software is here.
                </div>
              </div>
              <p className="mt-4 sm:mt-8 text-lg md:text-lg  sm:pr-32 lg:pr-96 text-left ">
                Operating a modern animal hospital can require you to run well over 10 separate software programs. Many of these solutions don't integrate, requiring your staff to jump between windows and enter duplicate data.
                Milo is different. It bundles all of your technology into one cohesive system. It's a practice management solution (PIMS), texting solution, phone system, customer portal and more.
              </p>



              <div className="mt-4 sm:mt-10 sm:justify-start sm:flex  lg:justify-start w-full">
                <div className="rounded-3xl shadow-xl">
                  <PrimaryButton copy="See our most popular features" href="#list" />
                </div>
                <div className="p-2" />
                <div className="rounded-3xl shadow-xl ">
                  <SecondaryButton copy="Schedule a demo" href="https://calendly.com/milo-demo/30min" onClick={() => ReactGA.event({ category: 'DEMO', action: 'Schedule Demo', label: 'Features Hero' })}/>
                  
                </div>
              </div>
              {/* <p className="mt-4 text-xl pt-4 max-w-1/2 text-center">
                <CtaLink copy=" View our full list of 500+ features here" href="mailto:hello@getmilo.com" mttop="mt-2 text-xl" /> 
          Below is just a sampling of the innovative features that make Milo standout from the pack.
          </p> */}
            </div>




            {/* <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:pt-32 lg:pb-20 xl:py-32 lg:text-left " >
              <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16 ">
                <h1 className="text-5xl tracking-tight font-semibold text-gray-900 sm:text-6xl  bg-blend-overlay">
                  <span className="block xl:inline opacity-80">One window to rule them all</span>{' '}
                </h1>
                <p className="mt-3 max-w-md mx-auto text-lg text-black sm:text-xl md:mt-5 md:max-w-3xl">
                  Let's be honest, veterinary software is a mess. The average staff members has over four windows open at a time. Not only does this slow them down, it leads to mistakes and frustrates your team and customers. That's why Milo takes an all-in-one approach to simplify your technology.
                </p>
                <p className="mt-3 max-w-md mx-auto text-lg text-black sm:text-xl md:mt-5 md:max-w-3xl">
                  Milo's all-in-one software comes with 25 apps, 50 modules and 1000+ features. You'll never need to pay extra or upgrade to access future features. They are made available in your software automatically every week. Below is a sampling of just a few of the features that come with Milo.
                </p>

                <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                  <div className="left">
                    <CtaLink copy="See a sampling of our features below" mttop="mt-2 text-xl" />
                  </div>


                </div>
              </div>
            </div>
            <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
              <img
                className="absolute top-32 w-full h-1/2 object-cover object-left z-50"
                src={Mess}
                alt=""
              />
            </div> */}
          </main>
        </div>
      </div >
    </div >
  )
}

export default HeroSection;
