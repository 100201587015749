import React from 'react';
import ReactGA, { ga } from 'react-ga';
import CtaLink from '../../cta-link';
import { CheckIcon, XIcon } from '@heroicons/react/solid'

ReactGA.initialize('UA-171076918-2', { gaOptions: { siteSpeedSampleRate: 100 } });
ReactGA.pageview(window.location.pathname + window.location.search);

const plans = [
    {
        title: 'Milo CRM',
        featured: false,
        description: 'Give your current PIMS a major upgrade with modern / customer centric features',
        priceMonthly: 'Standard',
        priceYearly: 'per hospital pricing',
        mainFeatures: [
            { id: 1, value: 'Integrates with your current PIMS' },
            { id: 2, value: 'Securely store credit cards w/ subscription membership' },
            { id: 3, value: 'Mobile credit card chip reader' },
            { id: 4, value: 'Texting and automated reminders' },
            { id: 5, value: 'Fully automated online reviews management' },
        ],
    },
    {
        title: 'All-in-one animal hospital software',
        featured: true,
        description: 'A truly all-in-one system for animal hospitals. Get all of your software from one place',
        priceMonthly: 'Standard',
        priceYearly: 'per DVM pricing',
        mainFeatures: [
            { id: 1, value: 'All features of Milo CRM and...' },
            { id: 2, value: 'Replaces all of your hospital software & PIMS' },
            { id: 3, value: 'Integrated payment processing and saved credit cards' },
            { id: 4, value: 'Phone system integration with caller ID' },
            { id: 5, value: 'Unlimited users accounts and upgrades' },
            { id: 6, value: 'Online customer portal with true online booking' },
        ],
    },
    {
        title: 'All-in-one PLUS',
        featured: false,
        description: 'Let us manage more than just your software: payment processing, phone service, web hosting and more',
        priceMonthly: 'Standard',
        priceYearly: 'designed to fit your needs',
        mainFeatures: [
            { id: 1, value: 'All features of Milo All-in-one and...' },
            { id: 3, value: 'Replaces your credit card processor and fees' },
            { id: 4, value: 'Replaces your phone system and phone bill' },
            { id: 5, value: 'Unlimited users, texting, calls and upgrades' },
            { id: 6, value: 'Replaces your website hosting company' },
        ],
    },

]
const overview = [
    {
        title: 'All-in-one-software (Replaces your PIMS and more)',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },

    {
        title: 'Subscription memberships',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Automated reviews management',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Client communication, reminders & text messages ',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Online appointment booking for clients ',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'GSuite (employee email and calendars) ',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Hospital website and hosting ',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },


    {
        title: 'Phone service & phones',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },

]


const software = [
    {
        title: 'Unlimited user accounts',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'PIMS / EHR',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },

    {
        title: 'Online booking',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Calendar & scheduling',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Configure your own subscription membership program',
        tiers: [
            { title: 'starter', value: 'Basic' },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Task management',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Labs & X-Rays',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },

    {
        title: 'Inventory management',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Configure your own Milo automations',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Customer portal and online booking',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Hospital Website',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Prescription shipping',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },

    {
        title: 'Whiteboard',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Integrated phones',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: 'Optional' },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Integrated payments & PCI complaint stored credit cards',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Reporting',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },

    {
        title: 'Client texting & communication',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },

    {
        title: 'Fully automated review management',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Reminders',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },


]
const services = [
    {
        title: 'Credit card fees',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Credit card terminals',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Phone service',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Phones or headsets',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Hospital website and hosting',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Domain registration',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Employee email & calendar from GSuite',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'eFax',
        tiers: [
            { title: 'starter', value: false },
            { title: 'popular', featured: true, value: false },
            { title: 'intermediate', value: true },
        ],
    },

]


const added = [
    {
        title: 'Cloud based software',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Secure remote access',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Dedicated customer success manager',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Nightly data back up',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Two-factor authentication',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Redundant data storage',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Sign in with Google',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Works on most tablets and mobile devices',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Continued training',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Support for multiple locations and multiple regions',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Automatic and unlimited upgrades to new features',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'API access',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },
    {
        title: 'Access to pre-built integrations with new ones launching monthly',
        tiers: [
            { title: 'starter', value: true },
            { title: 'popular', featured: true, value: true },
            { title: 'intermediate', value: true },
        ],
    },

]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Example() {
    return (
        <div className="bg-gray-50">



            {/* Plan price comparison and header $$$$$*/}
            <div className="relative">
                {/* Overlapping background */}
                <div aria-hidden="true" className="hidden absolute bg-gray-50 w-full h-6 bottom-0 xl:block" />


                {/* Overlapping background */}
                <div aria-hidden="true" className="hidden absolute bg-gray-50 w-full h-6 bottom-0" />



                <h2 className="sr-only">Plans</h2>
                <a name="prices" />
                <div className="mx-auto max-w-md px-4 pt-12 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-3xl">
                    <p className="text-left lg:text-center mt-2 text-6xl font-extrabold text-black/80 tracking-tight uppercase font-condensed sm:pr-10">
                        Plans that fit hospitals of any size.
                    </p>
                    <p className="mt-5 max-w-prose mx-auto text-xl text-black/80 text-left">
                        Milo takes a different approach to pricing. Rather than adding another invoice to your monthly bills, we designed Milo to replace upwards of 10 existing bills. Our goal is that hospitals that switch to Milo see little to no increase in short term cost, and over the long term, see significant savings.
                    </p>
                </div>

                {/* Cards */}
                <div className="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0 ">
                    {/* Decorative background */}
                    <div
                        aria-hidden="true"
                        className="hidden absolute top-4 bottom-6 left-8 right-8 inset-0 bg-purple rounded-tl-lg rounded-tr-lg lg:block"
                    />

                    <div className="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3">
                        {plans.map((plan) => (
                            <div
                                key={plan.title}
                                className={classNames(
                                    plan.featured ? 'bg-white ring-2 ring-purple shadow-md' : 'bg-purple lg:bg-transparent',
                                    'pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12'
                                )}
                            >
                                <div>
                                    <h3
                                        className={classNames(
                                            plan.featured ? 'text-purple' : 'text-white',
                                            'text-sm font-semibold uppercase tracking-wide'
                                        )}
                                    >
                                        {plan.title}
                                    </h3>
                                    <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                                        <div className="mt-3 flex items-center">
                                            <p
                                                className={classNames(
                                                    plan.featured ? 'text-purple' : 'text-white',
                                                    'text-4xl font-extrabold tracking-tight'
                                                )}
                                            >
                                                {plan.priceMonthly}
                                            </p>
                                            <div className="ml-4">
                                                <p className={classNames(plan.featured ? 'text-gray-700' : 'text-white', 'text-sm')}>
                                                    {plan.priceYearly}
                                                </p>
                                                <p className={classNames(plan.featured ? 'text-gray-500' : 'text-purple-200', 'text-sm')}>

                                                </p>
                                            </div>
                                        </div>
                                        <a
                                            href="/contact"
                                            className={classNames(
                                                plan.featured
                                                    ? 'bg-purple-600 text-white hover:bg-purple-700'
                                                    : 'bg-white text-purple-600 hover:bg-purple-50',
                                                'mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full'
                                            )}
                                        >
                                            Buy {plan.title}
                                        </a>
                                    </div>
                                </div>
                                <h4 className="sr-only">Features</h4>

                                <ul

                                    className={classNames(
                                        plan.featured
                                            ? 'border-gray-200 divide-gray-200'
                                            : 'border-purple-500 divide-purple-500 divide-opacity-75',
                                        'mt-7 border-t divide-y lg:border-t-0'
                                    )}
                                >
                                    {plan.mainFeatures.map((mainFeature) => (
                                        <li key={mainFeature.id} className="py-3 flex items-center">
                                            <CheckIcon
                                                className={classNames(
                                                    plan.featured ? 'text-purple-500' : 'text-purple-200',
                                                    'w-5 h-5 flex-shrink-0'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span
                                                className={classNames(
                                                    plan.featured ? 'text-gray-600' : 'text-white',
                                                    'ml-3 text-sm font-medium'
                                                )}
                                            >
                                                {mainFeature.value}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="mt-10 text-l pt-4 max-w-1/2 text-center">
                Looking for something in between one of our packages?
                <CtaLink
                    copy="Get in Touch"
                    href="/contact"
                    mttop="mt-2 text-m"
                // onClick={eventTrack.bind(this, "CONTACTUS", "PRICING", "SOMETHING_DIFFERENT")}


                >

                </CtaLink>
            </div>
            <a name="savings" />

            <div className="mx-auto max-w-md px-4 pt-20 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-3xl">
                <p className="text-left lg:text-center mt-2 text-6xl font-extrabold text-black/80 tracking-tight uppercase font-condensed">
                    Milo isn't a new expense, it replaces ones you already have today.
                </p>
                <p className="mt-8 max-w-prose mx-auto text-xl text-black/80 text-left">
                    Other software packages obfuscate what it really costs to use their software. There are high up-front fees, seperate costs for customer service or the need to upgrade from outdated versions. Arguably the most important cost these software packages include is the one placed on your staff. Any shortcomings in the design of their software falls onto your team.
                </p>
                <p className="mt-8 max-w-prose mx-auto text-xl text-black/80 text-left">
                    Milo though, isn't just a PIMS. We will replace your website, website hosting, digital marketing, reminders software, phone systems, credit card processor, client portal/app, telemedicine solution and so much more. If you add up all those bills, they likely total more than the cost of Milo.

                </p>
            </div>
            {/* Feature comparison (up to lg) MOBILE MOBILE MOBILE $$$$$*/}
            <section aria-labelledby="mobile-comparison-heading" className="lg:hidden">
                <h2 id="mobile-comparison-heading" className="sr-only">
                    Feature comparison
                </h2>

                <div className="max-w-2xl mx-auto py-16 px-4 space-y-16 sm:px-6">
                    {plans.map((plan, mobilePlanIndex) => (
                        <div key="plan.title" className="border-t border-gray-200">

                            {/* PLAN TITLES */}
                            <div
                                className={classNames(
                                    plan.featured ? 'border-purple-600' : 'border-transparent',
                                    '-mt-px pt-6 border-t-2 sm:w-1/2'
                                )}
                            >
                                <h3 className={classNames(plan.featured ? 'text-purple-600' : 'text-gray-900', 'text-sm font-bold')}>
                                    {plan.title}
                                </h3>
                                <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                            </div>

                            {/* mobile OVERVIEW */}
                            <h4 className="mt-10 text-sm font-bold text-gray-900">Overview</h4>

                            <div className="mt-6 relative">
                                {/* Fake card background */}
                                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                                    <div
                                        className={classNames(
                                            plan.featured ? 'shadow-md' : 'shadow',
                                            'absolute right-0 w-1/2 h-full bg-white rounded-lg'
                                        )}
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        plan.featured ? 'ring-2 ring-purple-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow',
                                        'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none'
                                    )}
                                >
                                    <dl className="divide-y divide-gray-200">
                                        {overview.map((feature) => (
                                            <div
                                                key={feature.title}
                                                className="py-3 flex items-center justify-between sm:grid sm:grid-cols-2"
                                            >
                                                <dt className="pr-4 text-sm font-medium text-gray-600">{feature.title} </dt>                                  {/* mobile side bar software */}
                                                <dd className="flex items-center justify-end sm:px-4 sm:justify-center">
                                                    {typeof feature.tiers[mobilePlanIndex].value === 'string' ? (
                                                        <span
                                                            className={classNames(
                                                                feature.tiers[mobilePlanIndex].featured ? 'text-purple-600' : 'text-gray-900',
                                                                'text-sm font-medium'
                                                            )}
                                                        >
                                                            {feature.tiers[mobilePlanIndex].value}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {feature.tiers[mobilePlanIndex].value === true ? (
                                                                <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                                                            ) : (
                                                                <XIcon className="mx-auto h-5 w-5 text-gray-200" aria-hidden="true" />
                                                            )}

                                                            <span className="sr-only">
                                                                {feature.tiers[mobilePlanIndex].value === true ? 'Yes' : 'No'}
                                                            </span>
                                                        </>
                                                    )}
                                                </dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>

                                {/* Fake card border */}
                                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                                    <div
                                        className={classNames(
                                            plan.featured ? 'ring-2 ring-purple-600' : 'ring-1 ring-black ring-opacity-5',
                                            'absolute right-0 w-1/2 h-full rounded-lg'
                                        )}
                                    />
                                </div>
                            </div>


                            {/* mobile SOFTWARE */}
                            <h4 className="mt-10 text-sm font-bold text-gray-900">Software</h4>

                            <div className="mt-6 relative">

                                {/* Fake card background */}
                                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                                    <div
                                        className={classNames(
                                            plan.featured ? 'shadow-md' : 'shadow',
                                            'absolute right-0 w-1/2 h-full bg-white rounded-lg'
                                        )}
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        plan.featured ? 'ring-2 ring-purple-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow',
                                        'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none'
                                    )}
                                >
                                    <dl className="divide-y divide-gray-200">
                                        {software.map((perk) => (
                                            <div key={perk.title} className="py-3 flex justify-between sm:grid sm:grid-cols-2">
                                                <dt className="text-sm font-medium text-gray-600 sm:pr-4">{perk.title}</dt>
                                                <dd className="text-center sm:px-4">
                                                    {perk.tiers[mobilePlanIndex].value === true ? (
                                                        <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                                                    ) : (
                                                        <XIcon className="mx-auto h-5 w-5 text-gray-200" aria-hidden="true" />
                                                    )}

                                                    <span className="sr-only">{perk.tiers[mobilePlanIndex].value === true ? 'Yes' : 'No'}</span>
                                                </dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>

                                {/* Fake card border */}
                                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                                    <div
                                        className={classNames(
                                            plan.featured ? 'ring-2 ring-purple-600' : 'ring-1 ring-black ring-opacity-5',
                                            'absolute right-0 w-1/2 h-full rounded-lg'
                                        )}
                                    />
                                </div>
                            </div>

                            {/* mobile SERVICEES */}
                            <h4 className="mt-10 text-sm font-bold text-gray-900">Services</h4>

                            <div className="mt-6 relative">

                                {/* Fake card background */}
                                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                                    <div
                                        className={classNames(
                                            plan.featured ? 'shadow-md' : 'shadow',
                                            'absolute right-0 w-1/2 h-full bg-white rounded-lg'
                                        )}
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        plan.featured ? 'ring-2 ring-purple-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow',
                                        'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none'
                                    )}
                                >
                                    <dl className="divide-y divide-gray-200">
                                        {services.map((perk) => (
                                            <div key={perk.title} className="py-3 flex justify-between sm:grid sm:grid-cols-2">
                                                <dt className="text-sm font-medium text-gray-600 sm:pr-4">{perk.title}</dt>
                                                <dd className="text-center sm:px-4">
                                                    {perk.tiers[mobilePlanIndex].value === true ? (
                                                        <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                                                    ) : (
                                                        <XIcon className="mx-auto h-5 w-5 text-gray-200" aria-hidden="true" />
                                                    )}

                                                    <span className="sr-only">{perk.tiers[mobilePlanIndex].value === true ? 'Yes' : 'No'}</span>
                                                </dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>

                                {/* Fake card border */}
                                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                                    <div
                                        className={classNames(
                                            plan.featured ? 'ring-2 ring-purple-600' : 'ring-1 ring-black ring-opacity-5',
                                            'absolute right-0 w-1/2 h-full rounded-lg'
                                        )}
                                    />
                                </div>
                            </div>

                            {/* Mobile ADDED*/}
                            <h4 className="mt-10 text-sm font-bold text-gray-900">Always Included</h4>

                            <div className="mt-6 relative">

                                {/* Fake card background */}
                                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                                    <div
                                        className={classNames(
                                            plan.featured ? 'shadow-md' : 'shadow',
                                            'absolute right-0 w-1/2 h-full bg-white rounded-lg'
                                        )}
                                    />
                                </div>

                                <div
                                    className={classNames(
                                        plan.featured ? 'ring-2 ring-purple-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow',
                                        'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none'
                                    )}
                                >
                                    <dl className="divide-y divide-gray-200">
                                        {added.map((perk) => (
                                            <div key={perk.title} className="py-3 flex justify-between sm:grid sm:grid-cols-2">
                                                <dt className="text-sm font-medium text-gray-600 sm:pr-4">{perk.title}</dt>
                                                <dd className="text-center sm:px-4">
                                                    {perk.tiers[mobilePlanIndex].value === true ? (
                                                        <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                                                    ) : (
                                                        <XIcon className="mx-auto h-5 w-5 text-gray-200" aria-hidden="true" />
                                                    )}

                                                    <span className="sr-only">{perk.tiers[mobilePlanIndex].value === true ? 'Yes' : 'No'}</span>
                                                </dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>

                                {/* Fake card border */}
                                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                                    <div
                                        className={classNames(
                                            plan.featured ? 'ring-2 ring-purple-600' : 'ring-1 ring-black ring-opacity-5',
                                            'absolute right-0 w-1/2 h-full rounded-lg'
                                        )}
                                    />
                                </div>
                            </div>




                        </div>
                    ))}
                </div>
            </section>


            {/* Feature comparison (lg+) LARGE LARGE LARGE $$$$$ */}
            <section aria-labelledby="comparison-heading" className="hidden lg:block">
                <h2 id="comparison-heading" className="sr-only">
                    Feature comparison
                </h2>

                <div className="max-w-7xl mx-auto py-24 px-8">
                    {/* Large feature compare title row  with description of section next to it*/}
                    <div className="w-full border-t border-gray-200 flex items-stretch">
                        <div className="-mt-px w-1/4 py-6 pr-4 flex items-end">
                            <h3 className="mt-auto text-sm font-bold text-gray-900">Overview</h3>
                        </div>
                        {plans.map((plan, planIdx) => (
                            <div
                                key={plan.title}
                                aria-hidden="true"
                                className={classNames(planIdx === plans.length - 1 ? '' : 'pr-4', '-mt-px pl-4 w-1/4')}
                            >
                                <div
                                    className={classNames(plan.featured ? 'border-purple-600' : 'border-transparent', 'py-6 border-t-2')}
                                >
                                    <p className={classNames(plan.featured ? 'text-purple-600' : 'text-gray-900', 'text-sm font-bold')}>
                                        {plan.title}     {/* large format top title row, description below */}
                                    </p>
                                    <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* LG Overview */}
                    <div className="relative">
                        {/* Fake card backgrounds */}
                        <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow-md" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                        </div>

                        <table className="relative w-full">
                            <caption className="sr-only">Business feature comparison</caption>
                            <thead>
                                <tr className="text-left">
                                    <th scope="col">
                                        <span className="sr-only">Feature </span>
                                    </th>
                                    {plans.map((plan) => (
                                        <th key={plan.title} scope="col">
                                            <span className="sr-only">{plan.title} plan</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                                {overview.map((feature) => (
                                    <tr key={feature.title}>
                                        <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                            {feature.title}     {/* row headers X's and checks FIRST SECTION*/}
                                        </th>
                                        {feature.tiers.map((tier, tierIdx) => (
                                            <td
                                                key={tier.title}
                                                className={classNames(
                                                    tierIdx === feature.tiers.length - 1 ? 'pl-4' : 'px-4',
                                                    'relative w-1/4 py-0 text-center'
                                                )}
                                            >
                                                <span className="relative w-full h-full py-3">
                                                    {typeof tier.value === 'string' ? (
                                                        <span
                                                            className={classNames(
                                                                tier.featured ? 'text-purple-600' : 'text-gray-900',
                                                                'text-sm font-medium'
                                                            )}
                                                        >
                                                            {tier.value}     {/* Features X's and checks */}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {tier.value === true ? (
                                                                <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                                                            ) : (
                                                                <XIcon className="mx-auto h-5 w-5 text-gray-200" aria-hidden="true" />
                                                            )}

                                                            <span className="sr-only">{tier.value === true ? 'Yes' : 'No'}</span>
                                                        </>
                                                    )}
                                                </span>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Fake card borders */}
                        <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-2 ring-purple-600 ring-opacity-100" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                        </div>
                    </div>
                    {/* LG SOftware */}
                    <h3 className="mt-10 text-sm font-bold text-gray-900">Software</h3>
                    {/* Services X's and checks */}
                    <div className="mt-6 relative">
                        {/* Fake card backgrounds */}
                        <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow-md" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                        </div>

                        <table className="relative w-full">
                            <caption className="sr-only">Perk comparison</caption>
                            <thead>
                                <tr className="text-left">
                                    <th scope="col">
                                        <span className="sr-only">Perk</span>
                                    </th>
                                    {plans.map((plan) => (
                                        <th key={plan.title} scope="col">
                                            <span className="sr-only">{plan.title} plan</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                                {software.map((perk) => (
                                    <tr key={perk.title}>
                                        <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                            {perk.title}     {/* Services row headers */}
                                        </th>
                                        {perk.tiers.map((tier, tierIdx) => (
                                            <td
                                                key={tier.title}
                                                className={classNames(
                                                    tierIdx === perk.tiers.length - 1 ? 'pl-4' : 'px-4',
                                                    'relative w-1/4 py-0 text-center'
                                                )}
                                            >
                                                <span className="relative w-full h-full py-3">
                                                    {tier.value === true ? (
                                                        <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                                                    ) : (
                                                        <XIcon className="mx-auto h-5 w-5 text-gray-200" aria-hidden="true" />
                                                    )}
                                                    {/* Services X's and checks MD*/}
                                                    <span className="sr-only">{tier.value === true ? 'Yes' : 'No'}</span>
                                                </span>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Fake card borders */}
                        <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-2 ring-purple-600 ring-opacity-100" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                        </div>
                    </div>


                    {/* LG Services */}
                    <h3 className="mt-10 text-sm font-bold text-gray-900">Services</h3>
                    {/* Services X's and checks */}
                    <div className="mt-6 relative">
                        {/* Fake card backgrounds */}
                        <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow-md" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                        </div>

                        <table className="relative w-full">
                            <caption className="sr-only">Perk comparison</caption>
                            <thead>
                                <tr className="text-left">
                                    <th scope="col">
                                        <span className="sr-only">Perk</span>
                                    </th>
                                    {plans.map((plan) => (
                                        <th key={plan.title} scope="col">
                                            <span className="sr-only">{plan.title} plan</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                                {services.map((perk) => (
                                    <tr key={perk.title}>
                                        <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                            {perk.title}     {/* Services row headers */}
                                        </th>
                                        {perk.tiers.map((tier, tierIdx) => (
                                            <td
                                                key={tier.title}
                                                className={classNames(
                                                    tierIdx === perk.tiers.length - 1 ? 'pl-4' : 'px-4',
                                                    'relative w-1/4 py-0 text-center'
                                                )}
                                            >
                                                <span className="relative w-full h-full py-3">
                                                    {tier.value === true ? (
                                                        <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                                                    ) : (
                                                        <XIcon className="mx-auto h-5 w-5 text-gray-200" aria-hidden="true" />
                                                    )}
                                                    {/* Services X's and checks MD*/}
                                                    <span className="sr-only">{tier.value === true ? 'Yes' : 'No'}</span>
                                                </span>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Fake card borders */}
                        <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-2 ring-purple-600 ring-opacity-100" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                        </div>
                    </div>

                    {/* LG Always included */}
                    <h3 className="mt-10 text-sm font-bold text-gray-900">Always Included</h3>
                    {/* Services X's and checks */}
                    <div className="mt-6 relative">
                        {/* Fake card backgrounds */}
                        <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full bg-white rounded-lg shadow-md" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full bg-white rounded-lg shadow" />
                            </div>
                        </div>

                        <table className="relative w-full">
                            <caption className="sr-only">Perk comparison</caption>
                            <thead>
                                <tr className="text-left">
                                    <th scope="col">
                                        <span className="sr-only">Perk</span>
                                    </th>
                                    {plans.map((plan) => (
                                        <th key={plan.title} scope="col">
                                            <span className="sr-only">{plan.title} plan</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                                {added.map((perk) => (
                                    <tr key={perk.title}>
                                        <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                                            {perk.title}     {/* Services row headers */}
                                        </th>
                                        {perk.tiers.map((tier, tierIdx) => (
                                            <td
                                                key={tier.title}
                                                className={classNames(
                                                    tierIdx === perk.tiers.length - 1 ? 'pl-4' : 'px-4',
                                                    'relative w-1/4 py-0 text-center'
                                                )}
                                            >
                                                <span className="relative w-full h-full py-3">
                                                    {tier.value === true ? (
                                                        <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                                                    ) : (
                                                        <XIcon className="mx-auto h-5 w-5 text-gray-200" aria-hidden="true" />
                                                    )}
                                                    {/* Services X's and checks MD*/}
                                                    <span className="sr-only">{tier.value === true ? 'Yes' : 'No'}</span>
                                                </span>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Fake card borders */}
                        <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                            <div className="w-1/4 pr-4" />
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                            <div className="w-1/4 px-4">
                                <div className="w-full h-full rounded-lg ring-2 ring-purple-600 ring-opacity-100" />
                            </div>
                            <div className="w-1/4 pl-4">
                                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </div>
    )
}
