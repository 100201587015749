import React from 'react';


import { ShieldCheckIcon } from '@heroicons/react/outline'


const features = [

    {
        name: 'Are deeply empathetic and open to new ideas.',
        description: ' ',
      },
      {
        name: 'Are willing to work hard and smart enough to take breaks.',
        description: ' ',
      },
      {
        name: 'Are intellectually curious and honest.',
        description: ' ',
      },
      {
        name: 'Embrace humility and reject egotism.',
        description: ' ',
      },
  {
    name: 'Listen attentively to our customers, fellow employees, communities and shareholders.',
    description: ' ',
  },
  {
    name: 'Assume positive intent and believe that people will strive to bring their best to the table.',
    description: ' ',
  },


  {
    name: 'Understand that part of growth is delivering and accepting feedback.',
    description: ' ',
  },

  

  {
    name: 'Win as a team. We compete with the external world, not amongst each other.',
    description: ' ',
  },


  {
    name: 'Lean into our strengths as individuals, and recognize that the different strengths of others should be celebrated and not compared.',
    description: ' ',
  },
  {
    name: 'Value the journey as much as the destination. How we do what we do matters as much as, if not more than, the outcome.',
    description: ' ',
  },
  {
    name: 'Regularly question and adapt our ideas and core values to ensure they are the right ones.',
    description: ' ',
  },
]

export default function Example() {
  return (
    <div id="corevalues" className="bg-white">
  
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-6xl font-extrabold tracking-tight text-gray-900 uppercase font-condensed">
        
                  Milo's Core Values
                  
                </h2>
          <p className="mt-4 text-lg text-gray-500">
          Don’t worry, there isn’t a quiz at the end. 11 core values might seem like a lot, but each captures an important aspect of what it’s like to work at Milo. We want to be up front about what behaviors we look for in candidates; they directly reflect the values we reward on our team.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <ShieldCheckIcon className="absolute h-6 w-6 purple" aria-hidden="true" />
                <p className="ml-9 pl-0 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              {/* <dd className="mt-2 ml-9 text-xs text-gray-500">{feature.description}</dd> */}
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}